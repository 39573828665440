import Axios from 'axios';
import { IGameInitResponse, IGameSpinResponse, IJackpotBalance } from './types';
import { IGameStat } from '../../types';
import { Game } from '../../game';

const PROD_URL = 'https://slots-engine.accamax.com';
const SANDBOX_URL = 'https://slots-engine.sandbox.accamax.com';
const DEMO_URL = 'https://slots-engine.demo.accamax.com';

const prepareBody = (token: string) => {
  const queryParams = getQueryParams();
  const subdomain = window.location.hostname.split('.')[1]; // Extracts "sandbox"
  const path = window.location.pathname; // "/[platform]"
  const isLocalhost = window.location.hostname.toLowerCase() === 'localhost';
  const isPreview = window.location.hostname.includes('pages.dev');

  const isDemo =
    queryParams['demo'] && queryParams['demo'].toLowerCase() === 'true';

  const platform = isDemo
    ? 'demo'
    : path.split('/').filter(Boolean).pop()?.toLowerCase(); // "[platform]

  const isSandbox =
    isPreview ||
    isLocalhost ||
    (subdomain && subdomain.toLowerCase() === 'sandbox');

  const FINAL_URL = isDemo ? DEMO_URL : isSandbox ? SANDBOX_URL : PROD_URL;

  switch (platform) {
    case 'prn': {
      return {
        finalUrl: FINAL_URL,
        data: {
          platformCode: platform || 'demo',
          userCode: queryParams['customer'] || 'gg',
          brandCode: queryParams['trader'] || 'tst1',
          channel: queryParams['platform'] || 'm',
          currency: queryParams['currency'] || 'usd',
          gameCode: queryParams['gameid'] || '00001',
          country: queryParams['country'] || 'tr',
          lang: queryParams['lang'] || 'en',
          lobby: queryParams['lobby'] || 'http://lobby',
          demo: queryParams['demo'] || 'false',
          token: queryParams['token'] || token,
        },
      };
    }
    default: {
      return {
        finalUrl: FINAL_URL,
        data: {
          platformCode: platform || 'demo',
          userCode: queryParams['customer'] || 'gg',
          brandCode: queryParams['trader'] || 'tst1',
          channel: queryParams['platform'] || 'm',
          currency: queryParams['currency'] || 'usd',
          gameCode: queryParams['gameid'] || '00001',
          country: queryParams['country'] || 'tr',
          lang: queryParams['lang'] || 'en',
          lobby: queryParams['lobby'] || 'http://lobby',
          demo: queryParams['demo'] || 'false',
          token: queryParams['token'] || token,
        },
      };
    }
  }
};

export const getGameInitData = async (token: string) => {
  // TODO: get baseurl from environment

  const dataPrep = prepareBody(token);

  const result = (
    await Axios.post(dataPrep.finalUrl + '/Game/Init', dataPrep.data)
  ).data;
  return result as IGameInitResponse;
};
export const getGameStats = async (token: string) => {
  const dataPrep = prepareBody(token);
  const result = (
    await Axios.post(dataPrep.finalUrl + '/Game/Ping', dataPrep.data)
  ).data;
  return result as {
    stats: IGameStat;
    majorJackpot: IJackpotBalance;
    grandJackpot: IJackpotBalance;
  };
};
export const getGameSpin = async (
  game: Game,
  buyFreeSpins: boolean = false
) => {
  const config = {
    headers: {
      SymbolsTest:
        window.testData?.testSymbols &&
        JSON.stringify(window.testData?.testSymbols), // Your custom header
    },
  };

  const dataPrep = prepareBody(game.id);
  const result = (
    await Axios.post(
      dataPrep.finalUrl + '/Game/Spin',
      {
        ...dataPrep.data,
        stake: game.stake,
        buyFreeSpins,
        anteBetEnabled: game.anteBetActive,
      },
      config
    )
  ).data;
  return result as IGameSpinResponse;
};

function getQueryParams(): Record<string, string> {
  const params = new URLSearchParams(window.location.search);
  const queryParams: Record<string, string> = {};

  // Iterate over all parameters
  params.forEach((value, key) => {
    queryParams[key] = value;
  });

  return queryParams;
}
