import { registerLogCategory } from '../../../debug/privateLogger';
import SoundBase, { TSoundDuckingChangeListener } from './soundBase';
import { GameEvent } from '../../../games/gameEvent';

const musicLog = registerLogCategory('Music');

class Music extends SoundBase {
  constructor(
    musicName: string,
    {
      onDuckingChange,
      duckingVolumeMultiplier,
    }: {
      onDuckingChange?: GameEvent<TSoundDuckingChangeListener>;
      duckingVolumeMultiplier?: number;
    } = {},
  ) {
    super(musicName, { onDuckingChange, duckingVolumeMultiplier });

    musicLog(1)('constructor', musicName);
  }

  play({
         loop = false,
         onComplete,
       }: {
         loop?: boolean;
         onComplete?: () => void;
       } = {}) {
    musicLog(2)('play', this._soundName, { loop });

    this._sound.loop = loop;

    if (onComplete)
      this.onComplete(onComplete);

    if (this._sound.isPlaying)
      return;

    this._play();
  }

  pause() {
    musicLog(2)('pause', this._soundName);
    this._sound.pause();
  }

  stop() {
    musicLog(2)('stop', this._soundName);
    this._sound.stop();
  }
}

export default Music;
