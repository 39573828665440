import { Container, Graphics, Sprite, Texture, Ticker } from 'pixi.js';
import Reel from './models/reel/index';
import delay from 'delay';
import { Dede } from '.';
import {
  DELAY_AFTER_SELECTION,
  LANDSCAPE_LOGO_OFFSET_X,
  LANDSCAPE_LOGO_OFFSET_Y,
  PORTRAIT_LOGO_OFFSET_X,
  PORTRAIT_LOGO_OFFSET_Y,
  REELS_BACKGROUND_OFFSET_X,
  REELS_BACKGROUND_OFFSET_Y,
  REELS_CONTAINER_OFFSET_X,
  REELS_CONTAINER_OFFSET_Y,
  REELS_COUNT,
  REELS_MASK_OFFSET,
  REELS_ROOF_OFFSET_X,
  REELS_ROOF_OFFSET_Y,
  SEPARATORS_CONTAINER_OFFSET_X,
  SEPARATORS_CONTAINER_OFFSET_Y,
  SHORT_DIMENSION,
  SYMBOL_GAP,
  SYMBOL_HEIGHT,
  SYMBOL_SHAKE_DURATION,
  SYMBOL_SHAKE_MAGNITUDE,
  SYMBOL_WIDTH,
  SYMBOLS_CONTAINER_OFFSET_X,
  SYMBOLS_CONTAINER_OFFSET_Y,
  SYMBOLS_PER_REEL,
} from './resources/constants';
import { IGameOutcome } from './service/types';
import { Game } from '../game';

export class ReelsManager {
  public reels: Reel[] = [];
  private destroyedReelsCount = 0;
  private fallCompleteTimeout?: NodeJS.Timeout;
  private randomNumbers: number[][] = [];
  container = new Graphics();
  separatorContainer = new Graphics();
  symbolsContainer = new Sprite();
  mask = new Graphics();
  backgroundSprite = new Sprite();
  containerSprite = new Sprite();
  roofSprite = new Sprite();
  reelSeparators: Sprite[] = [];
  logoSprite = new Sprite();
  startPositionY = 0;
  endPositionY = 0;
  _scale = 1;

  get scale() {
    return this._scale;
  }

  constructor(
    private game: Dede,
    private events: {
      onDestroy: () => void;
      onFallComplete: (multiplier: number, outcome?: IGameOutcome) => void;
    },
  ) {
    // this.symbolsContainer.rect(0, 0, 100, 100);

    this.container.addChild(this.symbolsContainer);
    this.container.alpha = 0.98;
    this.container.zIndex = 2;
  }

  loadNumbers(incomingNumber: number[][], clearPrevious: boolean, winningSymbols?: number[]) {
    let reversed = incomingNumber;
    if (this.randomNumbers.length === 0) {
      this.randomNumbers = reversed;
    }
    else if (clearPrevious) {
      this.randomNumbers.forEach((reelNumbers, i) => {
        reelNumbers.push(...reversed[i]);
      });
    }
    else {
      reversed.forEach((reelNumbers, i) => {
        const newSymbols = reelNumbers.slice(
          0,
          reelNumbers.length
          - this.reels[i].symbols.filter(
            (symbol) => !winningSymbols!.includes(symbol.symbolType) && symbol.symbolType < 100000,
          ).length,
        );

        this.randomNumbers[i].push(...newSymbols);
      });
    }
  }

  processOutcome(clearPrevious: boolean, winningSymbols?: number[]) {
    if (!clearPrevious)
      this.game.prevOutcome = this.game.currentOutcome;
    else
      this.game.prevOutcome = undefined;
    this.game.currentOutcome = this.game.outcomes.shift();
    if (this.game.currentOutcome) {
      this.loadNumbers(this.game.currentOutcome.symbols, clearPrevious, winningSymbols);
    }
  }

  createReelsMask(withBigOffset = true) {
    const { height, width, x, y } = this.backgroundSprite;
    this.mask?.destroy?.();

    this.mask = new Graphics();
    const offset = -REELS_MASK_OFFSET;
    this.mask.rect(x - offset, y - offset - 30, width + offset * 2, height + offset * 2);

    this.mask.fill('transparent');
    this.container.addChild(this.mask);
    this.symbolsContainer.mask = this.mask;
    this.symbolsContainer.alpha = 0.99;
  }

  handleResize = () => {
    this._scale = 1;
    const orientation = this.game.getOrientation();

    if (orientation === 'portrait') {
      if (this.game.width < SHORT_DIMENSION) {
        this._scale = (this._scale * this.game.width) / SHORT_DIMENSION;
      }
    }
    else {
      if (this.game.height < SHORT_DIMENSION) {
        this._scale = (this._scale * this.game.height) / SHORT_DIMENSION;
      }
    }
    this.container.scale.set(this._scale);

    const scaledContainerWidth = this.container.width / this._scale;
    const scaledContainerHeight = this.container.height / this._scale;

    this.startPositionY = -100;
    this.endPositionY =
      this.startPositionY + (SYMBOL_HEIGHT + SYMBOL_GAP) * (SYMBOLS_PER_REEL - 1) + 170;

    this.backgroundSprite.x = (scaledContainerWidth - this.backgroundSprite.width) / 2;
    this.backgroundSprite.y = REELS_BACKGROUND_OFFSET_Y;
    this.backgroundSprite.alpha = 0.99;

    this.symbolsContainer.zIndex = 1;
    this.symbolsContainer.x = SYMBOLS_CONTAINER_OFFSET_X;
    this.symbolsContainer.y = SYMBOLS_CONTAINER_OFFSET_Y;

    this.containerSprite.x = REELS_CONTAINER_OFFSET_X;
    this.containerSprite.y = REELS_CONTAINER_OFFSET_Y;
    this.containerSprite.scale.set(1.26);

    if (orientation === 'portrait') {
      // this.logoSprite.x = (scaledContainerWidth - this.logoSprite.width) / 2;
      // this.logoSprite.y = PORTRAIT_LOGO_OFFSET_Y;
      this.logoSprite.visible = false;
    }
    else {
      // this.logoSprite.x = LANDSCAPE_LOGO_OFFSET_X;
      // this.logoSprite.y = LANDSCAPE_LOGO_OFFSET_Y;
      this.logoSprite.visible = false;
    }
    this.createReelsMask();
    this.container.x = (this.game.width - this.container.width) / 2;
    this.container.y = (this.game.height - this.container.height) / 2 + 600 * this.scale;

    this.roofSprite.x = (this.game.width - this.roofSprite.width) / 2;
    this.roofSprite.y = this.container.y - 190 * this.scale;
    this.roofSprite.scale.set(1.4 * this.scale);
  };

  async mount() {
    this.backgroundSprite = new Sprite(Texture.from('reelBackground'));
    this.containerSprite = new Sprite(Texture.from('reelContainer'));
    this.roofSprite = new Sprite(Texture.from('reelRoof'));
    this.roofSprite.zIndex = 2;
    this.reelSeparators = new Array(REELS_COUNT - 1).fill(0).map((_, i) => {
      const separator = new Sprite(Texture.from('reelSeparator'));
      separator.x = i * (SYMBOL_WIDTH + SYMBOL_GAP) + SYMBOL_WIDTH;
      this.separatorContainer.addChild(separator);
      return separator;
    });
    this.separatorContainer.x = SEPARATORS_CONTAINER_OFFSET_X;
    this.separatorContainer.y = SEPARATORS_CONTAINER_OFFSET_Y;
    this.logoSprite = new Sprite(Texture.from('logo'));
    this.logoSprite.zIndex = 2;

    this.game.onFreeSpinChange.addEventListener((event, freeSpinState) => {
      if (freeSpinState.active) {
        this.containerSprite.texture = Texture.from('fsReelContainer');
      }
      else {
        this.containerSprite.texture = Texture.from('reelContainer');
      }
    });
    this.container.addChild(this.mask);
    this.container.addChild(this.backgroundSprite);
    this.container.addChild(this.containerSprite);
    this.game.app.stage.addChild(this.roofSprite);
    this.container.addChild(this.logoSprite);
    this.container.addChild(this.separatorContainer);
    this.game.app.stage.addChild(this.container);

    this.handleResize();
    this.handleResize();
    this.game.onResize.addEventListener(this.handleResize);

    this.reels = new Array(REELS_COUNT).fill(0).map((_, i) => {
      return new Reel(
        this.game,
        this.symbolsContainer,
        i,
        SYMBOLS_PER_REEL,
        this.startPositionY,
        this.randomNumbers[i],
        {
          onDestroy: this.handleDestroy,
          onFallComplete: this.handleFallComplete,
          onExplodeComplete: this.handleExplodeComplete,
        },
      );
    });

    for (let i = 0; i < this.reels.length; i++) {
      this.reels[i].start();
      await delay(Game.settings.animation.symbolAnimation.delayBetweenTwoReel);
    }
    this.createReelsMask(true);

    this.game.onNewSymbolsComing.addEventListener(() => {
      this.createReelsMask(true);
    });

    this.game.onOldSymbolsDropping.addEventListener(() => {
      this.createReelsMask(false);
    });
  }

  update(ticker: Ticker) {
    this.reels.forEach((el) => el.update(ticker));
  }

  async runReels(buyFreeSpins: boolean = false) {
    const destroyReels = async () => {
      this.game.onOldSymbolsDropping.triggerEvent();
      for (let i = 0; i < this.reels.length; i++) {
        this.reels[i].destroy();
        await delay(Game.settings.animation.symbolAnimation.delayBetweenTwoReel);
      }
    };
    destroyReels();
    await this.game.serviceManager.getSpin(buyFreeSpins);
    this.processOutcome(true);
  }

  async runReelsWithOutcomes(outcomes: IGameOutcome[]) {
    // console.log('Running reels with outcomes');
    this.game.outcomes = outcomes;
    this.processOutcome(true);
    for (let i = 0; i < this.reels.length; i++) {
      this.reels[i].destroy();
      await delay(Game.settings.animation.symbolAnimation.delayBetweenTwoReel);
    }
  }

  private handleExplodeComplete = async () => {
    // console.log('handleExplodeComplete');
    const allCompleted = this.reels.every(
      (reel) => reel.explodedSymbols.length === reel.explodingSymbols.length,
    );
    if (!allCompleted)
      return;

    this.reels.forEach((reel) => {
      reel.moveAfterExplode();
    });
    for (const reel of this.reels) {
      reel.moveAfterExplode();
      await delay(50);
    }
  };

  private handleFallComplete = async () => {
    const allCompleted = this.reels.every((reel) => !reel.moving);
    if (!allCompleted)
      return;

    this.game.soundManager.stopTumble();

    if (this.fallCompleteTimeout)
      clearTimeout(this.fallCompleteTimeout);
    this.fallCompleteTimeout = setTimeout(async () => {
      const winnings = this.game.currentOutcome?.winnings?.filter(
        (winning) => winning.winningSymbol !== this.game.config.scatterSymbol,
      );
      const multiplier = this.game.currentOutcome?.multiplier || 0;
      if (winnings?.length) {
        this.game.tumble(this.game.currentOutcome!);

        this.reels.forEach((reel) => {
          reel.explodingSymbols = [];
          reel.explodedSymbols = [];
        });

        this.game.onSymbolSelection.triggerEvent();
        setTimeout(() => {
          this.game.onExplode.triggerEvent();
        }, DELAY_AFTER_SELECTION);

        const winningSymbols = winnings.map((winning) => winning.winningSymbol);
        winnings.forEach((winning) => {
          this.reels.forEach((reel) => {
            reel.symbols.forEach(async (symbol) => {
              if (symbol.symbolType === Number(winning.winningSymbol)) {
                reel.explodingSymbols.push(symbol);
                symbol.select();
                symbol.shake(SYMBOL_SHAKE_MAGNITUDE, SYMBOL_SHAKE_DURATION);
                await delay(DELAY_AFTER_SELECTION);
                symbol.explode();
              }
            });
          });
        });

        // console.log('currentOutcomecurrentOutcome', this.game.currentOutcome);
        this.events.onFallComplete(multiplier, this.game.currentOutcome);
        this.processOutcome(false, winningSymbols);
      }
      else {
        // console.log('else currentOutcomecurrentOutcome', this.game.currentOutcome);
        this.events.onFallComplete(multiplier, this.game.currentOutcome);
      }
    }, 100);
  };

  private handleDestroy = async () => {
    this.destroyedReelsCount++;
    if (this.destroyedReelsCount === REELS_COUNT) {
      this.destroyedReelsCount = 0;
      this.symbolsContainer.removeChildren();

      const startNextSpin = async () => {
        this.game.onNewSymbolsComing.triggerEvent();
        for (let i = 0; i < this.reels.length; i++) {
          this.reels[i].start();
          await delay(Game.settings.animation.symbolAnimation.delayBetweenTwoReel);
        }
        this.events.onDestroy();
        this.game.onPendingSpinResponseChange.removeEventListener(startNextSpin);
      };
      if (!this.game.pendingSpinResponse)
        await startNextSpin();
      else {
        this.game.onPendingSpinResponseChange.addEventListener(startNextSpin);
      }
    }
  };

  printWinAmount(outcome?: IGameOutcome) {
    outcome?.winnings.forEach((win) => {
      if (!win.winAmount)
        return;
      const reelsFound = this.reels.filter((reel) =>
        reel.symbols.some((s) => s.symbolType === win.winningSymbol));
      const middleReel = reelsFound[Math.floor(reelsFound.length / 2)];
      const symbolsFound = middleReel.symbols.filter((s) => s.symbolType === win.winningSymbol);
      const middleSymbol = symbolsFound[Math.floor(symbolsFound.length / 2)];
      middleSymbol.showText(win.winAmount.toString(), '#ffffff', 90);
    });
  }

  getDynamicBounds() {
    const x = this.symbolsContainer.x;
    const y = this.endPositionY - (SYMBOL_HEIGHT + SYMBOL_GAP) * (SYMBOLS_PER_REEL - 1);
    const width = (SYMBOL_WIDTH + SYMBOL_GAP) * REELS_COUNT - SYMBOL_GAP;
    const height = (SYMBOL_HEIGHT + SYMBOL_GAP) * SYMBOLS_PER_REEL - SYMBOL_GAP;
    return { x, y, width, height };
  }
}
