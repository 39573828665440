import { Application, Container, Graphics, Point, Sprite, Text, Texture } from 'pixi.js';
import { GameEvent } from '../../../gameEvent';
import { ResizeListener } from '../../../types';
import { ReelsManager } from '../../ReelsManager';
import { GlowFilter } from 'pixi-filters';
import { formatAsCurrency } from '../../../../game/managers/currencyManager';
import { registerLogCategory } from '../../../../debug/privateLogger';
import { REELS_MASK_OFFSET } from '../../resources/constants';

const log = registerLogCategory('LayoutManager');

type TLayoutManagerGameInterface = {
  width: number;
  height: number;
  reelsManager: ReelsManager;
  onResize: GameEvent<ResizeListener>;
  app: Application;
};

class LayoutManager {
  private _gameInterface!: TLayoutManagerGameInterface;
  private _pixiWinHistoryContainer!: Container;
  private _pixiTotalWinContainer!: Container;

  constructor(gameInterface: TLayoutManagerGameInterface) {
    log(1)('LayoutManager->constructor', { gameInterface });
    this._gameInterface = gameInterface;
  }

  get onResize() {
    return this._gameInterface.onResize;
  }

  public setupPixiContainers() {
    this._prepareBottomTextGradient();
    this._setupWiningsBarContainer();
  }

  /** WiningsBar Container */

  static winingsBarContainerXOffset = 1164;
  static winingsBarContainerYOffset = 770;
  static winingsBarContainerWidth = 633;
  static winingsBarContainerHeight = 74;
  static winHistoryWidthLimit = 430;

  get visibleWidthStageCoordinate() {
    return this._gameInterface.app.stage.toLocal(
      new Point(this._gameInterface.app.renderer.screen.width, 0),
    ).x;
  }

  get visibleHeightStageCoordinate() {
    return this._gameInterface.app.stage.toLocal(
      new Point(0, this._gameInterface.app.renderer.screen.height),
    ).y;
  }

  // Sets up a container that will hold both the balance and win history elements.
  private _setupWiningsBarContainer() {
    log(1)('LayoutManager->setupWiningsBarContainer', {
      gameInterface: this._gameInterface,
      LayoutManager,
    });

    const winningsBarContainer = new Container();
    this._gameInterface.reelsManager.containerSprite.addChild(winningsBarContainer);

    winningsBarContainer.x = LayoutManager.winingsBarContainerXOffset;
    winningsBarContainer.y = LayoutManager.winingsBarContainerYOffset;
    winningsBarContainer.width = LayoutManager.winingsBarContainerWidth;
    winningsBarContainer.height = LayoutManager.winingsBarContainerHeight;

    // const mask = new Graphics();
    // winningsBarContainer.addChild(mask);
    // mask.rect(0, 0, LayoutManager.winingsBarContainerWidth, LayoutManager.winingsBarContainerHeight);
    // mask.fill(0xffffff);
    // mask.alpha = 0.5;

    const winHistoryContainer = new Container();
    winningsBarContainer.addChild(winHistoryContainer);
    winHistoryContainer.x = LayoutManager.winingsBarContainerWidth - LayoutManager.winHistoryWidthLimit;

    this._pixiWinHistoryContainer = winHistoryContainer;

    const totalWinContainer = new Container();
    winningsBarContainer.addChild(totalWinContainer);
    this._pixiTotalWinContainer = totalWinContainer;
  }

  get winHistoryContainer() {
    if (typeof this._pixiWinHistoryContainer === 'undefined')
      throw new Error('Cannot access LayoutManager->winHistoryContainer without setupPixiContainers having been called');

    return this._pixiWinHistoryContainer;
  }

  get totalWinContainer() {
    if (typeof this._pixiTotalWinContainer === 'undefined')
      throw new Error('Cannot access LayoutManager->totalWinContainer without setupPixiContainers having been called');

    return this._pixiTotalWinContainer;
  }

  /** Balance and Bet Text */

  private _prepareBottomTextGradient() {
    log(1)('_prepareBottomTextGradient', {
      gameInterface: this._gameInterface,
    });

    const bottomTextGradientSprite = new Sprite(Texture.from('bottomTextGradient'));
    this._gameInterface.app.stage.addChild(bottomTextGradientSprite);
    bottomTextGradientSprite.x = 0;
    bottomTextGradientSprite.zIndex = 1;
    bottomTextGradientSprite.height = 85;

    const handleResize = () => {
      bottomTextGradientSprite.y = this.visibleHeightStageCoordinate - bottomTextGradientSprite.height;
      bottomTextGradientSprite.width = this._gameInterface.app.stage.width;
    };

    this.onResize.addEventListener(handleResize);
    handleResize();
  }

  static createBalanceStyleText(parentContainer: Container, {
    anchorX = 0, anchorY = 1, x, y, label,
  }: {
    anchorX?: number; anchorY?: number; x: number; y: number; label: string;
  }) {
    log(3)('createBalanceStyleText', { anchorX, anchorY, x, y, label });

    const container = new Container();
    parentContainer.addChild(container);

    const labelText = new Text();
    container.addChild(labelText);
    labelText.anchor.set(anchorX, anchorY);
    labelText.y = 0;
    labelText.style = {
      fill: 0xffffff,
      fontSize: 36,
      fontFamily: 'caesarDressingRegular',
    };

    const valueText = new Text();
    container.addChild(valueText);
    valueText.anchor.set(anchorX, anchorY);
    valueText.y = 4;
    valueText.style = {
      fill: 0xfe9b1c,
      fontSize: 52,
      fontFamily: 'brlnsr',
    };

    const glowFilter = new GlowFilter({
      distance: 3,
      outerStrength: 4,
      innerStrength: 0,
      color: 0x000000,
      quality: 0.5,
    });

    container.filters = [glowFilter];

    container.x = x;
    container.y = y;
    container.zIndex = 2;

    const updateValue = (value?: number) => {
      if (typeof value === 'undefined') {
        valueText.text = '';
        labelText.text = '';

        return;
      }

      labelText.text = label;
      valueText.text = formatAsCurrency(value);
      labelText.x = 0;
      valueText.x = 0;

      if (anchorX === 1)
        labelText.x = 0 - valueText.width - 13;
      else
        valueText.x = labelText.width + 13;
    };

    updateValue();

    return {
      updateValue,
      container,
    };
  }
};

export default LayoutManager;
