import { Sprite, Texture } from 'pixi.js';
import GameController from '.';
import Button from '../button';
import {
  LANDSCAPE_ADD_BET_BUTTON_CONTENT_X,
  LANDSCAPE_ADD_BET_BUTTON_CONTENT_Y,
  LANDSCAPE_ADD_BET_BUTTON_HEIGHT,
  LANDSCAPE_ADD_BET_BUTTON_WIDTH,
  LANDSCAPE_ADD_BET_BUTTON_X,
  LANDSCAPE_ADD_BET_BUTTON_Y,
  LANDSCAPE_AUTOPLAY_BUTTON_CONTENT_X,
  LANDSCAPE_AUTOPLAY_BUTTON_CONTENT_Y,
  LANDSCAPE_AUTOPLAY_BUTTON_HEIGHT,
  LANDSCAPE_AUTOPLAY_BUTTON_WIDTH,
  LANDSCAPE_AUTOPLAY_BUTTON_X,
  LANDSCAPE_AUTOPLAY_BUTTON_Y,
  LANDSCAPE_INFO_BUTTON_CONTENT_X,
  LANDSCAPE_INFO_BUTTON_CONTENT_Y,
  LANDSCAPE_INFO_BUTTON_X,
  LANDSCAPE_INFO_BUTTON_Y,
  LANDSCAPE_SETTINGS_BUTTON_X,
  LANDSCAPE_SETTINGS_BUTTON_Y,
  LANDSCAPE_SOUND_BUTTON_X,
  LANDSCAPE_SOUND_BUTTON_Y,
  LANDSCAPE_SPIN_BUTTON_CONTENT_HEIGHT,
  LANDSCAPE_SPIN_BUTTON_CONTENT_WIDTH,
  LANDSCAPE_SPIN_BUTTON_CONTENT_X,
  LANDSCAPE_SPIN_BUTTON_CONTENT_Y,
  LANDSCAPE_SPIN_BUTTON_HEIGHT,
  LANDSCAPE_SPIN_BUTTON_WIDTH,
  LANDSCAPE_SPIN_BUTTON_X,
  LANDSCAPE_SPIN_BUTTON_Y,
  LANDSCAPE_SPIN_CONTAINER_HEIGHT,
  LANDSCAPE_SPIN_CONTAINER_WIDTH,
  LANDSCAPE_SPIN_CONTAINER_X,
  LANDSCAPE_SPIN_CONTAINER_Y,
  LANDSCAPE_STAKE_BUTTON_CONTENT_X,
  LANDSCAPE_STAKE_BUTTON_CONTENT_Y,
  LANDSCAPE_STAKE_BUTTON_HEIGHT,
  LANDSCAPE_STAKE_BUTTON_SCALE,
  LANDSCAPE_STAKE_BUTTON_WIDTH,
  LANDSCAPE_STAKE_BUTTON_X,
  LANDSCAPE_STAKE_BUTTON_Y,
  LANDSCAPE_SUB_BET_BUTTON_CONTENT_X,
  LANDSCAPE_SUB_BET_BUTTON_CONTENT_Y,
  LANDSCAPE_SUB_BET_BUTTON_HEIGHT,
  LANDSCAPE_SUB_BET_BUTTON_WIDTH,
  LANDSCAPE_SUB_BET_BUTTON_X,
  LANDSCAPE_SUB_BET_BUTTON_Y,
  LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_HEIGHT,
  LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_WIDTH,
  LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_X,
  LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_Y,
  LANDSCAPE_TURBO_SPIN_BUTTON_HEIGHT,
  LANDSCAPE_TURBO_SPIN_BUTTON_WIDTH,
  LANDSCAPE_TURBO_SPIN_BUTTON_X,
  LANDSCAPE_TURBO_SPIN_BUTTON_Y,
  SMALL_BUTTON_HEIGHT,
  SMALL_BUTTON_WIDTH,
} from './constants';
import Popup from '../popup';
import CachedSettingsManager from '../../../../cachedSettingsManager';

export const mountLandscapeButtons = (controller: GameController) => {
  controller.settingsButton = new Button(controller.container, {
    backgroundTexture: 'config',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      controller.game.onInfoButtonClick.triggerEvent();
    },
    backgroundPosition: {
      x: LANDSCAPE_SETTINGS_BUTTON_X,
      y: LANDSCAPE_SETTINGS_BUTTON_Y,
      width: SMALL_BUTTON_WIDTH,
      height: SMALL_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: 0,
      y: 0,
    },
  });

  controller.soundButton = new Button(controller.container, {
    backgroundTexture: CachedSettingsManager.get('isSoundEnabled') ? 'sound' : 'soundDisabled',
    onClick: () => {
      controller.game.onClick.triggerEvent();

      CachedSettingsManager.set('isSoundEnabled', !CachedSettingsManager.get('isSoundEnabled'));
      controller.soundButton.background.texture = Texture.from(
        CachedSettingsManager.get('isSoundEnabled') ? 'sound' : 'soundDisabled',
      );
    },
    backgroundPosition: {
      x: LANDSCAPE_SOUND_BUTTON_X,
      y: LANDSCAPE_SOUND_BUTTON_Y,
      width: SMALL_BUTTON_WIDTH,
      height: SMALL_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: 0,
      y: 0,
    },
  });

  controller.infoButton = new Button(controller.container, {
    backgroundTexture: 'info',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      controller.game.onInfoButtonClick.triggerEvent();
    },
    backgroundPosition: {
      x: LANDSCAPE_INFO_BUTTON_X,
      y: LANDSCAPE_INFO_BUTTON_Y,
      width: SMALL_BUTTON_WIDTH,
      height: SMALL_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: LANDSCAPE_INFO_BUTTON_CONTENT_X,
      y: LANDSCAPE_INFO_BUTTON_CONTENT_Y,
    },
  });

  controller.goldenBetButton = new Button(controller.textButtonsContainer, {
    backgroundTexture: 'anteBetButton',
    text: controller.game.anteBetActive ? 'On' : 'Off',
    text2: '$' + controller.game.stake * 1.25,
    fontSize2: 35,
    onClick: () => {
      controller.game.onClick.triggerEvent();
      controller.game.anteBetActive = !controller.game.anteBetActive;
    },
    backgroundPosition: {
      x: 260 / 2,
      y: 300,
      width: 260,
      height: 228,
    },
    contentPosition: {
      x: 0,
      y: 60,
    },
    content2Position: {
      x: -50,
      y: -55,
    },
    text2Alignment: 'left',
  });

  controller.game.onAnteBetChange.addEventListener((_, value) => {
    if (value) {
      controller.goldenBetButton.text = 'On';
    }
    else {
      controller.goldenBetButton.text = 'Off';
    }
  });

  controller.game.onStakeChange.addEventListener((_, value) => {
    controller.goldenBetButton.text2 = '$' + value * 1.25;
  });

  controller.buyBonusButton = new Button(controller.textButtonsContainer, {
    backgroundTexture: 'buyBonusButton',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      controller.game.soundManager.bonusBuyOpen?.play();

      const popup = new Popup(
        ['Do you want to buy Bonus?'],
        () => {
          controller.game.soundManager.bonusBuyConfirm?.play();
          controller.game.runReels(true);
        },
        () => {},
      );
      popup.mount();
    },
    backgroundPosition: {
      x: 260 / 2,
      y: 170 / 2,
      width: 260,
      height: 170,
    },
    contentPosition: {
      x: 0,
      y: 0,
    },
  });
};
