import { Graphics, Sprite, Text, Texture } from 'pixi.js';
import { Dede } from '.';
import Popup from './models/popup';

export class AfterLoadManager {
  popup?: Popup;
  onOkClick!: () => void;

  constructor(private game: Dede) {
    this.popup = new Popup(
      ['Please Confirm ', 'You are 18+', 'Before Playing'],

      () => {
        this.onOkClick?.();
      },
      () => {},
    );
  }

  mountAfterLoadScreen(onOkClick?: () => void) {
    this.onOkClick = onOkClick!;
    this.popup!.mount();
  }
}
