import SoundManagerCore, { Music } from '../../../../game/managers/soundManager';
import accamaxDebug from '../../../../debug';

class SoundManager extends SoundManagerCore {
  private _bigWinMusicTypePlaying: Music | undefined = undefined;

  mountSounds() {
    this.registerMusic('musBaseGameMain', { isDuckable: true });
    this.registerMusic('musIntro', { isDuckable: true });
    this.registerMusic('musFreeSpinsIntro', { isDuckable: true });
    this.registerMusic('musFreeSpinsOutro', { isDuckable: true });
    this.registerMusic('musFreeSpins', { isDuckable: true });
    this.registerMusic('musBigWin');
    this.registerMusic('musMegaWin');
    this.registerMusic('musOlympusWin');
    this.registerMusic('musTumble', { isDuckable: true });
    this.registerSoundEffect('sfxSymExplode', { isDuckable: true });
    this.registerSoundEffect('sfxSymLand', { debounceTime: 100, isDuckable: true });
    this.registerSoundEffect('sfxSymHighlight', { isDuckable: true });
    this.registerSoundEffect('sfxClick');
    this.registerSoundEffect('sfxBonusBuyOpen');
    this.registerSoundEffect('sfxBonusBuyConfirm');
    this.registerSoundEffect('sfxScatterLand', { debounceTime: 100, isDuckable: true });
    this.registerSoundEffect('sfxScatterWin', { debounceTime: 100, isDuckable: true });
    this.registerSoundEffect('sfxMultiplierLand', { debounceTime: 100, isDuckable: true });
    this.registerSoundEffect('sfxMultiplierReveal', { debounceTime: 100, isDuckable: true });

    this._isMounted = true;

    this.getMusic('musIntro')?.play({ onComplete: () => {
      this.getMusic('musBaseGameMain')?.play({ loop: true });
    } });

    accamaxDebug.debug.duckTest = (winType = 'big') => {
      const music = this.getMusic(`mus${winType.substring(0, 1).toUpperCase() + winType.substring(1)}Win`) as Music;
      this.duckForSound(music);
      music.play();
    };
  }

  //
  // startFreeSpinsMusic() {
  //   if (!this._isMounted)
  //     throw new Error('Sounds are not mounted');
  //
  //   this.getMusic('musFreeSpinsIntro')?.play();
  // }

  playBigWin(type: 'big' | 'mega' | 'olympus') {
    if (!this._isMounted)
      throw new Error('Sounds are not mounted');

    if (this._bigWinMusicTypePlaying)
      this._bigWinMusicTypePlaying.stop();

    const bigWinMusic = this.getMusic(`mus${type.substring(0, 1).toUpperCase() + type.substring(1)}Win`) as Music;
    this.duckForSound(bigWinMusic);
    bigWinMusic.play();
  }

  get symbolExplode() {
    return this.getSoundEffect('sfxSymExplode');
  }

  get symbolLand() {
    return this.getSoundEffect('sfxSymLand');
  }

  get symbolHighlight() {
    return this.getSoundEffect('sfxSymHighlight');
  }

  get scatterLand() {
    return this.getSoundEffect('sfxScatterLand');
  }

  get scatterWin() {
    return this.getSoundEffect('sfxScatterWin');
  }

  get multiplierLand() {
    return this.getSoundEffect('sfxMultiplierLand');
  }

  get multiplierReveal() {
    return this.getSoundEffect('sfxMultiplierReveal');
  }

  startTumble() {
    this.getMusic('musTumble')?.play();
  }

  stopTumble() {
    // this.getMusic('musTumble')?.stop();
  }

  get click() {
    return this.getSoundEffect('sfxClick');
  }

  get bonusBuyOpen() {
    return this.getSoundEffect('sfxBonusBuyOpen');
  }

  get bonusBuyConfirm() {
    return this.getSoundEffect('sfxBonusBuyConfirm');
  }
}

export default SoundManager;
