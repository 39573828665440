import { Sprite, Texture } from 'pixi.js';
import GameController from '.';
import Button from '../button';
import {
  PORTRAIT_INFO_BUTTON_CONTENT_X,
  PORTRAIT_INFO_BUTTON_CONTENT_Y,
  PORTRAIT_INFO_BUTTON_X,
  PORTRAIT_INFO_BUTTON_Y,
  SMALL_BUTTON_WIDTH,
  SMALL_BUTTON_HEIGHT,
  PORTRAIT_SOUND_BUTTON_X,
  PORTRAIT_SOUND_BUTTON_Y,
  PORTRAIT_SETTINGS_BUTTON_X,
  PORTRAIT_SETTINGS_BUTTON_Y,
} from './constants';
import Popup from '../popup';
import { formatAsCurrency } from '../../../../game/managers/currencyManager';
import CachedSettingsManager from '../../../../cachedSettingsManager';

export const mountPortraitButtons = (controller: GameController) => {
  controller.settingsButton = new Button(controller.container, {
    backgroundTexture: 'config',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      controller.game.onInfoButtonClick.triggerEvent();
    },
    backgroundPosition: {
      x: PORTRAIT_SETTINGS_BUTTON_X,
      y: PORTRAIT_SETTINGS_BUTTON_Y,
      width: SMALL_BUTTON_WIDTH,
      height: SMALL_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: 0,
      y: 0,
    },
  });

  controller.soundButton = new Button(controller.container, {
    backgroundTexture: CachedSettingsManager.get('isSoundEnabled') ? 'sound' : 'soundDisabled',
    onClick: () => {
      controller.game.onClick.triggerEvent();

      CachedSettingsManager.set('isSoundEnabled', !CachedSettingsManager.get('isSoundEnabled'));
      controller.soundButton.background.texture = Texture.from(
        CachedSettingsManager.get('isSoundEnabled') ? 'sound' : 'soundDisabled',
      );
    },
    backgroundPosition: {
      x: PORTRAIT_SOUND_BUTTON_X,
      y: PORTRAIT_SOUND_BUTTON_Y,
      width: SMALL_BUTTON_WIDTH,
      height: SMALL_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: 0,
      y: 0,
    },
  });

  controller.infoButton = new Button(controller.container, {
    backgroundTexture: 'info',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      controller.game.onInfoButtonClick.triggerEvent();
    },
    backgroundPosition: {
      x: PORTRAIT_INFO_BUTTON_X,
      y: PORTRAIT_INFO_BUTTON_Y,
      width: SMALL_BUTTON_WIDTH,
      height: SMALL_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: PORTRAIT_INFO_BUTTON_CONTENT_X,
      y: PORTRAIT_INFO_BUTTON_CONTENT_Y,
    },
  });

  controller.goldenBetButton = new Button(controller.textButtonsContainer, {
    backgroundTexture: 'anteBetButton',
    text: controller.game.anteBetActive ? 'On' : 'Off',
    text2: '$' + controller.game.stake * 1.25,
    fontSize2: 35,
    onClick: () => {
      controller.game.onClick.triggerEvent();
      controller.game.anteBetActive = !controller.game.anteBetActive;
    },
    backgroundPosition: {
      x: 260 / 2,
      y: 114,
      width: 260,
      height: 228,
    },
    contentPosition: {
      x: 0,
      y: 60,
    },
    content2Position: {
      x: -50,
      y: -55,
    },
    text2Alignment: 'left',
  });

  controller.game.onAnteBetChange.addEventListener((_, value) => {
    if (value) {
      controller.goldenBetButton.text = 'On';
    }
    else {
      controller.goldenBetButton.text = 'Off';
    }
  });

  controller.game.onStakeChange.addEventListener((_, value) => {
    controller.goldenBetButton.text2 = formatAsCurrency(value * 1.25);
  });

  controller.buyBonusButton = new Button(controller.textButtonsContainer, {
    backgroundTexture: 'buyBonusButton',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      controller.game.soundManager.bonusBuyOpen?.play();

      const popup = new Popup(
        ['Do you want to buy Bonus?'],
        () => {
          controller.game.soundManager.bonusBuyConfirm?.play();
          controller.game.runReels(true);
        },
        () => {},
      );
      popup.mount();
    },
    backgroundPosition: {
      x: 900,
      y: 114 - 29,
      width: 260,
      height: 170,
    },
    contentPosition: {
      x: 0,
      y: 0,
    },
  });
};
