import { Graphics, Sprite, Text, Texture } from 'pixi.js';
import { Dede } from '.';
import { IFreeSpin, IGameSpinResponse } from './service/types';
import { IEventDetails } from '../gameEvent';
import Popup from './models/popup';

export class FreeSpinManager {
  private freeSpins: IFreeSpin[] = [];
  private numberOfFreeSpinsTriggered = 0;
  startPopup?: Popup;
  resultPopup?: Popup;
  totalMultiplierContainer?: Graphics;
  totalMultiplierText!: Text;
  resultContainer?: Graphics;

  container?: Graphics;
  remainingFreeSpinText!: Text;
  startFreeSpinButton?: Sprite;
  freeSpinIndex = 0;

  constructor(private game: Dede) {}

  processSpinResponse(spinResponse: IGameSpinResponse) {
    if (spinResponse.freeSpinsTriggered) {
      const startFreeSpin = () => {
        this.freeSpinIndex = 0;
        this.game.freeSpinWinAmount = 0;

        spinResponse.freeSpins.forEach((spin, x) => {
          const prevSpin = spinResponse.freeSpins[x - 1];
          spin.outcomes.forEach((outcome, index) => {
            outcome.isLastOutcome = index === spin.outcomes.length - 1;
            const spinData: any = {
              ...spin,
            };
            delete spinData.outcomes;
            outcome.spinData = spinData;
            outcome.isFreeSpin = true;
            outcome.totalWinAmount = outcome.isLastOutcome
              ? spin.runningTotal
              : (prevSpin?.runningTotal || 0) + outcome.runningTotal;
            outcome.tumbleWinAmount = outcome.isLastOutcome ? spin.winAmount : outcome.runningTotal;

            outcome.isLastFreeSpinOutcome =
              x === spinResponse.freeSpins.length - 1 && index === spin.outcomes.length - 1;
          });
        });
        this.freeSpins = spinResponse.freeSpins;

        const spinNext = () => {
          console.log('spinNext', this.freeSpinIndex, this.freeSpins);
          const spin = this.freeSpins[this.freeSpinIndex];
          this.numberOfFreeSpinsTriggered = spin.spinsLeft + spin.spinsPlayed;
          this.game.freeSpinTotalMultiplier = spin.aggregatedMultiplier;
          this.game.runFreeSpinReels(spin.outcomes);
          this.freeSpinIndex++;
        };
        spinNext();
        this.mountGraphics();

        const handleSpinComplete = (
          event: IEventDetails,
          params: { tumbleWinAmount: number; totalWinAmount: number }
        ) => {
          this.game.freeSpinWinAmount += params.tumbleWinAmount;
          if (this.freeSpinIndex < this.freeSpins.length) {
            if (this.game.paused) {
              const handleGameUnPaused = () => {
                console.log('handleGameUnPaused');
                this.game.onGameUnPaused.removeEventListener(handleGameUnPaused);
                spinNext();
                this.updateIndicators();
              };
              this.game.onGameUnPaused.addEventListener(handleGameUnPaused);
            } else {
              spinNext();
              this.updateIndicators();
            }
          } else {
            this.game.onSpinComplete.removeEventListener(handleSpinComplete);
            this.unmountGraphics();
            this.game.freeSpinActive = false;
            this.resultPopup = new Popup(['You won ' + this.game.freeSpinWinAmount + '!'], () => {
              this.game.onSpinComplete.removeEventListener(handleSpinComplete);
              this.unmountGraphics();
            });
            this.resultPopup.mount();
          }
        };
        this.game.onSpinComplete.addEventListener(handleSpinComplete);
      };
      const mountPopupCallback = () => {
        this.game.soundManager.scatterWin?.play();

        this.game.freeSpinTotalMultiplier = 1;
        this.game.freeSpinActive = true;
        this.numberOfFreeSpinsTriggered = spinResponse.numberOfFreeSpinsTriggered;

        this.game.onFallComplete.removeEventListener(mountPopupCallback);
        this.startPopup = new Popup(
          ['You won ', this.numberOfFreeSpinsTriggered + '', ' free spins!'],
          startFreeSpin
        );
        this.startPopup.mount();
      };
      this.game.onFallComplete.addEventListener(mountPopupCallback);
    }
  }

  mountGraphics() {
    this.unmountGraphics();
    const graphics = new Graphics();
    const sprite = new Sprite(Texture.from('freeSpinCounterBackground'));
    graphics.zIndex = 2;
    graphics.addChild(sprite);

    const title = new Text();
    graphics.addChild(title);
    title.text = 'Free Spins Left';
    title.style = { fill: 0xffffff, fontSize: 20 };
    title.x = (sprite.width - title.width) / 2;
    title.y = 230;

    const remainText = new Text();
    graphics.addChild(remainText);
    remainText.style = { fill: 0xffffff, fontSize: 36 };
    remainText.y = 255;

    const multiplierText = new Text();
    graphics.addChild(multiplierText);
    multiplierText.style = { fill: 0xffffff, fontSize: 48 };
    multiplierText.y = 165;

    this.game.app.stage.addChild(graphics);
    this.container = graphics;
    this.remainingFreeSpinText = remainText;
    this.totalMultiplierText = multiplierText;

    this.updateIndicators();

    this.handleResize();
    this.game.onResize.addEventListener(this.handleResize);
  }

  updateIndicators() {
    this.remainingFreeSpinText.text = `${this.numberOfFreeSpinsTriggered - this.freeSpinIndex} / ${
      this.numberOfFreeSpinsTriggered
    }`;

    this.remainingFreeSpinText.x = (this.container!.width - this.remainingFreeSpinText.width) / 2;

    this.totalMultiplierText.text = this.game.freeSpinTotalMultiplier;
    this.totalMultiplierText.x = (this.container!.width - this.totalMultiplierText!.width) / 2;
  }

  unmountGraphics() {
    this.game.onResize.removeEventListener(this.handleResize);
    this.container?.destroy();
  }

  handleResize = () => {
    if (this.container) {
      const orientation = this.game.getOrientation();
      if (orientation === 'portrait') {
        this.container.x = this.game.width / 2 + (this.game.width / 2 - this.container.width) / 2;
        this.container.y = 0;
      } else {
        this.container.x = this.game.width - this.container.width;
        this.container.y = -70;
        this.container.scale = this.game.scale;
      }
    }
  };
}
