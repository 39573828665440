import { Graphics, Sprite, Texture, Text, Container } from 'pixi.js';
import { Dede } from '.';
import { formatAsCurrency } from '../../game/managers/currencyManager';
import { GameEvent, IEventDetails } from '../gameEvent';
import { StakeChangeListener } from '../types';
import { performPulseAnimation } from '../../game/managers/animationManager/animations';

const X_OFFSET = 1355;
const Y_OFFSET = -20;
const COLUMN_SIZE = 266.666;
const JACKPOT_BANNER_TEXT_X_OFFSET = 2;
const JACKPOT_BANNER_TEXT_Y_OFFSET = 45;
const JACKPOT_BANNER_FONT_SIZE = 34;
const JACKPOT_BANNER_TEXT_LIMIT = 220;
const JACKPOT_MINOR_STAKE_MULTIPLIER = 25;
const JACKPOT_MINI_STAKE_MULTIPLIER = 10;

class ScaleTrackedText extends Text {
  public lastDigitCount = 1;
}

export class JackpotManager {
  container = new Graphics();

  private _grandText!: ScaleTrackedText;
  private _grandSprite!: Sprite;
  private _grandJackpotValue!: number;
  private _majorText!: ScaleTrackedText;
  private _majorSprite!: Sprite;
  private _majorJackpotValue!: number;
  private _minorText!: ScaleTrackedText;
  private _minorSprite!: Sprite;
  private _miniText!: ScaleTrackedText;
  private _miniSprite!: Sprite;
  private _jackpotLabelAnimationPromise?: Promise<void>;
  private _stakeValue!: number;
  private _stakeUpdateIsQueued: boolean = false;

  constructor(private game: Dede) {}

  async mount(stake: number, onStakeChange: GameEvent<StakeChangeListener>) {
    this._stakeValue = stake;

    let result = this._createJackpotVariant('jpGrand', 0x8e2014, this._grandJackpotValue, 0);
    this._grandSprite = result.sprite;
    this._grandText = result.valueText;
    result = this._createJackpotVariant('jpMajor', 0xa77300, this._majorJackpotValue, 1);
    this._majorSprite = result.sprite;
    this._majorText = result.valueText;
    result = this._createJackpotVariant('jpMinor', 0x3d7825, stake * JACKPOT_MINOR_STAKE_MULTIPLIER, 2);
    this._minorSprite = result.sprite;
    this._minorText = result.valueText;
    result = this._createJackpotVariant('jpMini', 0x2e487b, stake * JACKPOT_MINI_STAKE_MULTIPLIER, 3);
    this._miniSprite = result.sprite;
    this._miniText = result.valueText;

    this.game.reelsManager.container.addChild(this.container);

    this.container.x = X_OFFSET;
    this.container.y = Y_OFFSET;
    this.container.zIndex = 5;

    onStakeChange.addEventListener((event: IEventDetails, newStake: number) => {
      this._stakeValue = newStake;
      this._handleStakeChanged(newStake);
    });
  }

  private _updateJackpotVariantValue(
    value: number,
    container: Container,
    text: ScaleTrackedText,
    performAnimation = true,
  ) {
    if (!value || !text || !container)
      return;

    const origScale = text.scale.x;
    let targetScale = origScale;

    const textUpdateCallback = () => {
      const digitCount = Math.floor(Math.log10(value));
      if (digitCount !== text.lastDigitCount) {
        text.text = formatAsCurrency(Math.pow(10, digitCount) * 4);
        const lastScale = text.scale.x;
        text.scale.set(1);

        if (text.width > JACKPOT_BANNER_TEXT_LIMIT) {
          const overlap = text.width - JACKPOT_BANNER_TEXT_LIMIT;
          targetScale = (1 - overlap / text.width);
        }
        text.scale.set(lastScale);
        text.lastDigitCount = digitCount;
      }

      text.text = formatAsCurrency(value);
      return { scale: targetScale };
    };

    if (!performAnimation) {
      textUpdateCallback();
      return;
    }

    return performPulseAnimation(
      text,
      {
        midChangeCallback: textUpdateCallback,
        maxBrightness: 2,
        maxGlowStrength: 8,
        maxGlowDistance: 12,
        maxGrowScale: 1.1,
        durationGrow: 150,
        durationShrink: 150,
      },
    );
  }

  private _createJackpotVariant(spriteName: string, color: number, value: number, position: number) {
    const sprite = new Sprite(Texture.from(spriteName));
    this.container.addChild(sprite);
    sprite.x = COLUMN_SIZE * position;

    const valueText = new ScaleTrackedText();
    sprite.addChild(valueText);
    valueText.anchor.set(0.5, 0.5);
    valueText.x = sprite.width / 2 + JACKPOT_BANNER_TEXT_X_OFFSET;
    valueText.y = JACKPOT_BANNER_TEXT_Y_OFFSET;
    valueText.lastDigitCount = 0;
    valueText.style = {
      fill: color,
      fontSize: JACKPOT_BANNER_FONT_SIZE,
      fontFamily: 'brlnsr',
    };

    if (value)
      this._updateJackpotVariantValue(value, sprite, valueText, false);

    return { valueText, sprite };
  }

  public updateJackpotValues({ majorJackpotValue, grandJackpotValue }: {
    majorJackpotValue: number;
    grandJackpotValue: number;
  }) {
    if (this._grandJackpotValue !== grandJackpotValue) {
      this._grandJackpotValue = grandJackpotValue;
      this._jackpotLabelAnimationPromise = this._updateJackpotVariantValue(
        grandJackpotValue,
        this._grandSprite,
        this._grandText,
      );
    }

    if (this._majorJackpotValue !== majorJackpotValue) {
      this._majorJackpotValue = majorJackpotValue;
      this._updateJackpotVariantValue(majorJackpotValue, this._majorSprite, this._majorText);
    }
  };

  private async _handleStakeChanged(newStake: number) {
    this._stakeValue = newStake;
    if (this._stakeUpdateIsQueued)
      return;

    if (this._jackpotLabelAnimationPromise) {
      this._stakeUpdateIsQueued = true;
      await this._jackpotLabelAnimationPromise;
    }

    this._jackpotLabelAnimationPromise = this._updateJackpotVariantValue(
      this._stakeValue * JACKPOT_MINOR_STAKE_MULTIPLIER,
      this._minorSprite,
      this._minorText,
    );
    this._updateJackpotVariantValue(this._stakeValue * JACKPOT_MINI_STAKE_MULTIPLIER, this._miniSprite, this._miniText);
    this._stakeUpdateIsQueued = false;
  }
}
