import { Sprite, Texture } from 'pixi.js';
import GameController from '.';
import Button from '../button';
import {
  LANDSCAPE_ADD_BET_BUTTON_CONTENT_X,
  LANDSCAPE_ADD_BET_BUTTON_CONTENT_Y,
  LANDSCAPE_ADD_BET_BUTTON_HEIGHT,
  LANDSCAPE_ADD_BET_BUTTON_WIDTH,
  LANDSCAPE_ADD_BET_BUTTON_X,
  LANDSCAPE_ADD_BET_BUTTON_Y,
  LANDSCAPE_AUTOPLAY_BUTTON_CONTENT_X,
  LANDSCAPE_AUTOPLAY_BUTTON_CONTENT_Y,
  LANDSCAPE_AUTOPLAY_BUTTON_HEIGHT,
  LANDSCAPE_AUTOPLAY_BUTTON_WIDTH,
  LANDSCAPE_AUTOPLAY_BUTTON_X,
  LANDSCAPE_AUTOPLAY_BUTTON_Y,
  LANDSCAPE_INFO_BUTTON_CONTENT_X,
  LANDSCAPE_INFO_BUTTON_CONTENT_Y,
  LANDSCAPE_INFO_BUTTON_X,
  LANDSCAPE_INFO_BUTTON_Y,
  LANDSCAPE_SETTINGS_BUTTON_X,
  LANDSCAPE_SETTINGS_BUTTON_Y,
  LANDSCAPE_SOUND_BUTTON_X,
  LANDSCAPE_SOUND_BUTTON_Y,
  LANDSCAPE_SPIN_BUTTON_CONTENT_HEIGHT,
  LANDSCAPE_SPIN_BUTTON_CONTENT_WIDTH,
  LANDSCAPE_SPIN_BUTTON_CONTENT_X,
  LANDSCAPE_SPIN_BUTTON_CONTENT_Y,
  LANDSCAPE_SPIN_BUTTON_HEIGHT,
  LANDSCAPE_SPIN_BUTTON_WIDTH,
  LANDSCAPE_SPIN_BUTTON_X,
  LANDSCAPE_SPIN_BUTTON_Y,
  LANDSCAPE_SPIN_CONTAINER_HEIGHT,
  LANDSCAPE_SPIN_CONTAINER_WIDTH,
  LANDSCAPE_SPIN_CONTAINER_X,
  LANDSCAPE_SPIN_CONTAINER_Y,
  LANDSCAPE_STAKE_BUTTON_CONTENT_X,
  LANDSCAPE_STAKE_BUTTON_CONTENT_Y,
  LANDSCAPE_STAKE_BUTTON_HEIGHT,
  LANDSCAPE_STAKE_BUTTON_SCALE,
  LANDSCAPE_STAKE_BUTTON_WIDTH,
  LANDSCAPE_STAKE_BUTTON_X,
  LANDSCAPE_STAKE_BUTTON_Y,
  LANDSCAPE_SUB_BET_BUTTON_CONTENT_X,
  LANDSCAPE_SUB_BET_BUTTON_CONTENT_Y,
  LANDSCAPE_SUB_BET_BUTTON_HEIGHT,
  LANDSCAPE_SUB_BET_BUTTON_WIDTH,
  LANDSCAPE_SUB_BET_BUTTON_X,
  LANDSCAPE_SUB_BET_BUTTON_Y,
  LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_HEIGHT,
  LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_WIDTH,
  LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_X,
  LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_Y,
  LANDSCAPE_TURBO_SPIN_BUTTON_HEIGHT,
  LANDSCAPE_TURBO_SPIN_BUTTON_WIDTH,
  LANDSCAPE_TURBO_SPIN_BUTTON_X,
  LANDSCAPE_TURBO_SPIN_BUTTON_Y,
  SMALL_BUTTON_HEIGHT,
  SMALL_BUTTON_WIDTH,
} from './constants';

export const mountCommonButtons = (controller: GameController) => {
  controller.subBetButton = new Button(controller.blockContainer, {
    backgroundTexture: 'leftButton',
    texture: '-',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      const currentIndex =
        controller.game.config.limits.legalBets.indexOf(controller.game.stake) || 0;
      controller.game.stake =
        controller.game.config.limits.legalBets[
          (currentIndex - 1 + controller.game.config.limits.legalBets.length) %
            controller.game.config.limits.legalBets.length
        ];
    },
    backgroundPosition: {
      x: LANDSCAPE_ADD_BET_BUTTON_X,
      y: LANDSCAPE_ADD_BET_BUTTON_Y,
      width: LANDSCAPE_ADD_BET_BUTTON_WIDTH,
      height: LANDSCAPE_ADD_BET_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: LANDSCAPE_ADD_BET_BUTTON_CONTENT_X,
      y: LANDSCAPE_ADD_BET_BUTTON_CONTENT_Y,
    },
  });
  controller.addBetButton = new Button(controller.blockContainer, {
    backgroundTexture: 'rightButton',
    texture: '+',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      const currentIndex =
        controller.game.config.limits.legalBets.indexOf(controller.game.stake) || 0;
      controller.game.stake =
        controller.game.config.limits.legalBets[
          (currentIndex + 1) % controller.game.config.limits.legalBets.length
        ];
    },
    backgroundPosition: {
      x: LANDSCAPE_SUB_BET_BUTTON_X,
      y: LANDSCAPE_SUB_BET_BUTTON_Y,
      width: LANDSCAPE_SUB_BET_BUTTON_WIDTH,
      height: LANDSCAPE_SUB_BET_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: LANDSCAPE_SUB_BET_BUTTON_CONTENT_X,
      y: LANDSCAPE_SUB_BET_BUTTON_CONTENT_Y,
    },
  });

  controller.spinContainer = new Sprite(Texture.from('spinContainer'));
  controller.blockContainer.addChild(controller.spinContainer);
  controller.spinContainer.x = LANDSCAPE_SPIN_CONTAINER_X;
  controller.spinContainer.y = LANDSCAPE_SPIN_CONTAINER_Y;
  controller.spinContainer.width = LANDSCAPE_SPIN_CONTAINER_WIDTH;
  controller.spinContainer.height = LANDSCAPE_SPIN_CONTAINER_HEIGHT;

  controller.turboSpinButton = new Button(controller.blockContainer, {
    backgroundTexture: 'turboSpinBackground',
    texture: controller.game.turboSpinActive ? 'turboSpinActive' : 'turboSpinPassive',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      controller.game.turboSpinActive = !controller.game.turboSpinActive;
      controller.turboSpinButton.content.texture = Texture.from(
        controller.game.turboSpinActive ? 'turboSpinActive' : 'turboSpinPassive'
      );
    },
    backgroundPosition: {
      x: LANDSCAPE_TURBO_SPIN_BUTTON_X,
      y: LANDSCAPE_TURBO_SPIN_BUTTON_Y,
      width: LANDSCAPE_TURBO_SPIN_BUTTON_WIDTH,
      height: LANDSCAPE_TURBO_SPIN_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_X,
      y: LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_Y,
      width: LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_WIDTH,
      height: LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_HEIGHT,
    },
  });
  controller.stakeButton = new Button(controller.blockContainer, {
    backgroundTexture: 'betAmountBackground',
    text: '',
    fontSize: 48,
    onClick: () => {
      controller.game.onClick.triggerEvent();
    },
    backgroundPosition: {
      x: LANDSCAPE_STAKE_BUTTON_X,
      y: LANDSCAPE_STAKE_BUTTON_Y,
      width: LANDSCAPE_STAKE_BUTTON_WIDTH,
      height: LANDSCAPE_STAKE_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: LANDSCAPE_STAKE_BUTTON_CONTENT_X,
      y: LANDSCAPE_STAKE_BUTTON_CONTENT_Y,
    },
  });
  controller.stakeButton.disabled = true;

  controller.spinButton = new Button(controller.blockContainer, {
    backgroundTexture: 'spinBackground',
    texture: 'spin',
    disableShadow: true,
    onClick: () => {
      controller.game.onClick.triggerEvent();
      controller.game.runReels();
    },
    backgroundPosition: {
      x: LANDSCAPE_SPIN_BUTTON_X,
      y: LANDSCAPE_SPIN_BUTTON_Y,
      width: LANDSCAPE_SPIN_BUTTON_WIDTH,
      height: LANDSCAPE_SPIN_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: LANDSCAPE_SPIN_BUTTON_CONTENT_X,
      y: LANDSCAPE_SPIN_BUTTON_CONTENT_Y,
      width: LANDSCAPE_SPIN_BUTTON_CONTENT_WIDTH,
      height: LANDSCAPE_SPIN_BUTTON_CONTENT_HEIGHT,
    },
  });
  window.game?.onSpacePressed.addEventListener(() => {
    controller.spinButton.doClickEffect();
  });
  window.game?.onGamePaused.addEventListener(() => {
    controller.spinButton.disabled = true;
    controller.turboSpinButton.disabled = true;
  });
  window.game?.onGameUnPaused.addEventListener(() => {
    controller.spinButton.disabled = false;
    controller.turboSpinButton.disabled = false;
  });

  controller.autoPlayButton = new Button(controller.blockContainer, {
    backgroundTexture: 'autoSpin',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      if (controller.game.autoPlayCount === 0) {
        controller.game.autoPlayCount = -1;
      } else {
        controller.game.autoPlayCount = 0;
      }
    },
    backgroundPosition: {
      x: LANDSCAPE_AUTOPLAY_BUTTON_X,
      y: LANDSCAPE_AUTOPLAY_BUTTON_Y,
      width: LANDSCAPE_AUTOPLAY_BUTTON_WIDTH,
      height: LANDSCAPE_AUTOPLAY_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: LANDSCAPE_AUTOPLAY_BUTTON_CONTENT_X,
      y: LANDSCAPE_AUTOPLAY_BUTTON_CONTENT_Y,
    },
  });
  controller.game.onAutoplayChange.addEventListener((e, value) => {
    if (!controller.autoPlayButton) return;
    if (value.newAutoPlayCount === 0) {
      controller.autoPlayButton.background.texture = Texture.from('autoSpin');
    } else {
      controller.autoPlayButton.background.texture = Texture.from('autoSpinOn');
    }
  });
};
