import { Container, Graphics, Sprite, Text, Texture } from 'pixi.js';
import { IPosition } from '../../../types';
import { DropShadowFilter } from 'pixi-filters';

const TEXT_OFFSET_Y = 200;
const BUTTON_OFFSET_Y = 500;
const TEXT_GAP = 10;
const BUTTON_GAP = 200;

class Popup {
  container!: Graphics;
  background!: Sprite;
  okButton!: Sprite;
  cancelButton!: Sprite;
  texts: Text[] = [];
  constructor(texts: string[], private onOk: () => void, private onCancel?: () => void) {
    this.texts = texts.map((text, index) => {
      const textObject = new Text();
      textObject.text = text;
      textObject.style = { fill: 0xffffff, fontSize: 60 };
      return textObject;
    });
  }

  handleResize = () => {
    this.container.x = window.game!.width / 2 - this.container.width / 2;
    this.container.y = window.game!.height / 2 - this.container.height / 2;
    let textEndY = TEXT_OFFSET_Y;
    this.texts.forEach((text) => {
      text.x = this.background.width / 2 - text.width / 2;
      text.y = textEndY;
      textEndY = text.y + text.height + TEXT_GAP;
    });

    if (this.cancelButton) {
      this.cancelButton.x =
        (this.background.width - (this.cancelButton.width + BUTTON_GAP + this.okButton.width)) / 2
        + this.cancelButton.width / 2;
      this.cancelButton.y = BUTTON_OFFSET_Y;
    }
    this.okButton.x = this.cancelButton
      ? this.cancelButton.x + this.cancelButton.width + BUTTON_GAP
      : this.background.width / 2;
    this.okButton.y = BUTTON_OFFSET_Y;
  };

  mount() {
    this.container = new Graphics();
    this.container.zIndex = 4;
    window.game!.app.stage.addChild(this.container);
    this.background = new Sprite(Texture.from('popupBackground'));
    this.container.addChild(this.background);
    this.background.alpha = 0.95;
    this.texts.forEach((text) => this.background.addChild(text));
    this.okButton = this.initOkButton();
    if (this.onCancel)
      this.cancelButton = this.initCancelButton();
    window.game!.onResize.addEventListener(this.handleResize);
    this.handleResize();
  }

  initCancelButton() {
    const buttonTexture = Texture.from('closeButton'); // Use any button image
    const button = new Sprite(buttonTexture);
    this.background!.addChild(button);
    button.anchor.set(0.5);
    button.interactive = true;
    button.cursor = 'pointer'; // Set cursor to pointer on hover
    button.width = 120;
    button.height = 120;
    button.x = this.background!.width / 2 - 80;
    button.y = 140;
    button.zIndex = 100;
    button.alpha = 10000;

    button.on('pointerdown', () => {
      button.scale.set(button.scale.x * 0.9);
      button.tint = 0xaaaaaa;
    });
    button.on('pointerup', () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
      this.onCancel?.();
      this.container?.destroy();
    });
    button.on('pointerupoutside', () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
    });
    return button;
  }

  initOkButton() {
    const buttonTexture = Texture.from('checkButton'); // Use any button image
    const button = new Sprite(buttonTexture);
    this.background!.addChild(button);
    button.anchor.set(0.5);

    button.interactive = true;
    button.cursor = 'pointer'; // Set cursor to pointer on hover
    button.width = 120;
    button.height = 120;
    button.x = this.background!.width / 2 + 30;
    button.y = 140;
    button.zIndex = 100;
    button.alpha = 10000;

    button.on('pointerdown', () => {
      button.scale.set(button.scale.x * 0.9);
      button.tint = 0xaaaaaa;
    });
    button.on('pointerup', () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
      this.onOk();
      this.unMount();
    });
    button.on('pointerupoutside', () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
    });
    return button;
  }

  unMount() {
    this.container?.destroy();
    window.game!.onResize.removeEventListener(this.handleResize);
  }
}

export default Popup;
