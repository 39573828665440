import { Sprite, Texture } from 'pixi.js';
import { Dede } from '.';
import { IFreeSpinStatus } from '../types';
import { Spine } from '@pixi/spine-pixi';

export class BackgroundManager {
  spine!: Spine;
  freeSpinStatus?: IFreeSpinStatus;
  constructor(private game: Dede) {}

  unMountSpine() {
    if (this.spine) {
      // this.spine.destroy({ children: true, texture: true });
      this.game.app.stage.removeChild(this.spine);
    }
  }

  mountSpine(isFreeSpin = false) {
    if (!isFreeSpin) {
      this.spine = Spine.from({ skeleton: 'backgroundData', atlas: 'backgroundAtlas' });
      this.spine.state.setAnimation(0, 'animation', true);
    } else {
      this.spine = Spine.from({ skeleton: 'fsBackgroundData', atlas: 'fsBackgroundAtlas' });
      this.spine.state.setAnimation(0, 'animation', true);
    }
    this.spine.x = 1000;
    this.spine.y = 600;
    this.spine.scale.set(0.5);
    this.game.app.stage.addChild(this.spine);
    this.handleResize();
  }

  handleResize = () => {
    this.spine.x = this.game.width / 2;
    this.spine.y = this.game.height / 2;
    this.spine.scale.set(0.5);
  };

  async init() {
    await this.game.app.init({
      background: '#EA95EDFF',
    });
    this.mountSpine();

    this.game.onResize.addEventListener(this.handleResize);
    this.handleResize();

    // this.game.onResize.addEventListener((event, newSize) => {
    //   if (!this.spine) return;
    //   const orientation = this.game.getOrientation();
    //   if (orientation === 'portrait') {
    //     const scale = newSize.height / initialHeight;
    //     const scaledWidth = initialWidth * scale;
    //     const xOffset = (scaledWidth - newSize.width) / 2;
    //     this.spine.width = scaledWidth;
    //     this.spine.height = newSize.height;
    //     this.spine.y = 0;
    //     this.spine.x = -xOffset;
    //   } else {
    //     const scale = newSize.width / initialWidth;
    //     const scaledHeight = initialHeight * scale;
    //     const yOffset = (scaledHeight - newSize.height) / 2;
    //     this.spine.width = newSize.width;
    //     this.spine.height = scaledHeight;
    //     this.spine.y = -yOffset;
    //     this.spine.x = 0;
    //   }
    // });
    this.game.onFreeSpinChange.addEventListener((event, freeSpinStatus) => {
      if (!this.spine) return;
      if (this.freeSpinStatus?.active !== freeSpinStatus.active) {
        this.freeSpinStatus = freeSpinStatus;
        this.unMountSpine();
        this.mountSpine(freeSpinStatus.active);
      }
    });
  }
}
