import { Container, Graphics, Sprite, Texture, Ticker } from 'pixi.js';
import {
  HIGH_SYMBOL_SCALE, IS_MULTIPLIER_SYMBOL_TYPE,
  LOW_SYMBOL_SCALE,
  SCATTER_SYMBOL_SCALE, SCATTER_SYMBOL_TYPE,
  SCATTER_WIDTH,
  SHOW_BORDERS,
  SYMBOL_GAP,
  SYMBOL_HEIGHT,
  SYMBOL_WIDTH,
  SYMBOL_X_OFFSET,
  SYMBOL_Y_OFFSET,
} from '../../resources/constants';
import Symbol from '../symbol';
import { IReelEvents } from './types';
import { Dede } from '../..';
import { Game } from '../../../game';
import { Spine } from '@pixi/spine-pixi';

export default class Reel {
  symbols: Symbol[] = [];
  slotTextures: Texture[] = [];
  moving = false;
  explodingSymbols: Symbol[] = [];
  explodedSymbols: Symbol[] = [];
  completeTimeout: NodeJS.Timeout | null = null;
  private _hasProcessedLandSound = false;

  constructor(
    public game: Dede,
    public container: Container,
    public index: number,
    private symbolCount: number,
    private startY: number,
    private randomNumbers: number[],
    private events: IReelEvents
  ) {
    this.symbols = [];

    // Load the textures

    this.slotTextures = [
      Texture.from('s1'),
      Texture.from('s2'),
      Texture.from('s3'),
      Texture.from('s4'),
      Texture.from('s5'),
      Texture.from('s6'),
      Texture.from('s7'),
      Texture.from('s8'),
      Texture.from('s9'),
      Texture.from('s10'),
    ];
  }

  addSymbol(index = 0) {
    if (!this.container) return;
    const symbolType = this.randomNumbers.pop();
    if (symbolType === undefined) {
      console.log('symbolType is undefined');
      return;
    }
    const spine =
      symbolType > 100000
        ? Spine.from({
            skeleton: 'multiplierData',
            atlas: 'multiplierAtlas',
          })
        : Spine.from({
            skeleton: 's' + (symbolType + 1) + 'Data',
            atlas: 's' + (symbolType + 1) + 'Atlas',
          });
    spine.state.setAnimation(0, 'idle', false);
    this.container.addChild(spine);
    const isScatter = symbolType === this.game.config.scatterSymbol;
    if (symbolType < 5)
      spine.scale.set(LOW_SYMBOL_SCALE);
    else if (symbolType === SCATTER_SYMBOL_TYPE) {
      spine.scale.set(SCATTER_SYMBOL_SCALE);
    } else spine.scale.set(HIGH_SYMBOL_SCALE);
    
    if (SHOW_BORDERS) {
      let border = new Graphics();
      border.rect(0, 0, spine.width, spine.height);
      spine.addChild(border);
    }

    spine.y = this.startY + (isScatter ? 125 : 0) - index * (SYMBOL_HEIGHT + SYMBOL_GAP);
    spine.zIndex = isScatter ? 2 : 1;

    // TODO: this code is for test purpose. remove it when multiplier animations done
    // if (symbolType > 100000) {
    //   if (!this.game.paused) {
    //     this.game.paused = true;
    //     setTimeout(() => {
    //       this.game.paused = false;
    //     }, 3000);
    //   }
    // }

    const symbol = new Symbol(
      this,
      symbolType,
      spine,
      {
        start: {
          x:
            this.index * (SYMBOL_WIDTH + SYMBOL_GAP) +
            SYMBOL_X_OFFSET +
            (isScatter ? (SYMBOL_HEIGHT - SCATTER_WIDTH) / 2 : 0),
          y: spine.y,
        },
        end: {
          y:
            this.game.reelsManager.endPositionY +
            SYMBOL_Y_OFFSET -
            (SYMBOL_HEIGHT + SYMBOL_GAP) * this.symbols.length +
            (isScatter ? (SYMBOL_HEIGHT - SCATTER_WIDTH) / 2 : 0),
        },
      },

      {
        onDestroy: () => {
          this.container?.removeChild(spine);
          this.symbols = this.symbols.filter((s) => s !== symbol);
          if (this.symbols.length === 0) {
            this.events.onDestroy();
          }
        },
        onExplodeComplete: () => {
          this.explodedSymbols.push(symbol);
          if (this.explodedSymbols.length === this.explodingSymbols.length) {
            this.events.onExplodeComplete();
          }
          this._hasProcessedLandSound = false;
        },
        onFallComplete: () => {
          // This code executes on the first symbol to land in each reel after a spin or an explosion
          // It determines if the new symbols contain a scatter and plays the correct landing sound either way
          if (!this._hasProcessedLandSound) {
            this._hasProcessedLandSound = true;
            const newSymbolCount = this.explodedSymbols.length ? this.explodedSymbols.length : this.symbols.length;
            const newSymbols = this.symbols
              .slice(this.symbols.length - newSymbolCount)
              .map((symbol) => symbol.symbolType);
            const hasNewScatter = newSymbols.some((symbol) => symbol === SCATTER_SYMBOL_TYPE);
            const hasMultiplier = newSymbols.some(IS_MULTIPLIER_SYMBOL_TYPE);

            if (hasMultiplier)
              this.game.soundManager.multiplierLand?.play();
            else if (hasNewScatter)
              this.game.soundManager.scatterLand?.play();
            else
              this.game.soundManager.symbolLand?.play();
          }

          const allCompleted = this.symbols.every((symbol) => !symbol.falling);
          if (allCompleted) {
            this.moving = false;
            if (this.completeTimeout) clearTimeout(this.completeTimeout);
            this.completeTimeout = setTimeout(() => {
              this.events.onFallComplete();
            }, 100);
          }
        },
      },
      this.symbols.length
    );

    this.symbols.push(symbol);
  }

  async moveAfterExplode() {
    this.moving = true;
    const unExplodedSymbols = this.symbols
      .filter((s) => this.explodedSymbols.indexOf(s) === -1)
      .sort((a, b) => a.index - b.index);
    this.explodingSymbols.forEach((el) => el.deselect());
    this.symbols = unExplodedSymbols;
    unExplodedSymbols.forEach((symbol, index) => {
      symbol.fallDown(
        this.game.reelsManager.endPositionY -
          (SYMBOL_HEIGHT + SYMBOL_GAP) * index +
          SYMBOL_Y_OFFSET +
          (symbol.symbolType === 9 ? (SYMBOL_HEIGHT - SCATTER_WIDTH) / 2 : 0)
      );
      symbol.index = index;
    });
    for (let i = unExplodedSymbols.length; i < this.symbolCount; i++) {
      this.addSymbol(i);
    }
  }

  destroy() {
    this.symbols.forEach((symbol) => {
      symbol.destroy();
    });
  }

  async start() {
    this.symbols = [];
    this.moving = true;

    for (let i = 0; i < this.symbolCount; i++) {
      this.addSymbol(i);
      await new Promise((resolve) =>
        setTimeout(resolve, Game.settings.animation.symbolAnimation.delayBetweenTwoSymbol)
      );
    }
    this._hasProcessedLandSound = false;
  }

  update(ticker: Ticker) {
    this.symbols.forEach((symbol) => {
      symbol.update(ticker);
    });
  }
}
