export const PORTRAIT_WIDTH = 900;
export const PORTRAIT_HEIGHT = 400;
export const LANDSCAPE_WIDTH = 500;
export const LANDSCAPE_HEIGHT = 600;
export const BLOCK_CONTAINER_X_OFFSET = -1300;
export const BLOCK_CONTAINER_Y_OFFSET = 170;
export const TEXT_BUTTONS_CONTAINER_X_OFFSET = -1185;
export const TEXT_BUTTONS_CONTAINER_Y_OFFSET = -2150;
export const PORTRAIT_Y_OFFSET = 2740;
export const CONTAINER_Y_OFFSET = -1800;

export const PORTRAIT_ADD_BET_BUTTON_X = 320;
export const PORTRAIT_ADD_BET_BUTTON_Y = 180;
export const PORTRAIT_ADD_BET_BUTTON_WIDTH = 300;
export const PORTRAIT_ADD_BET_BUTTON_HEIGHT = 180;
export const PORTRAIT_ADD_BET_BUTTON_CONTENT_X = -60;
export const PORTRAIT_ADD_BET_BUTTON_CONTENT_Y = 1;
export const PORTRAIT_ADD_BET_BUTTON_CONTENT_WIDTH = 110;
export const PORTRAIT_ADD_BET_BUTTON_CONTENT_HEIGHT = 75;
export const PORTRAIT_SUB_BET_BUTTON_X = 580;
export const PORTRAIT_SUB_BET_BUTTON_Y = 180;
export const PORTRAIT_SUB_BET_BUTTON_WIDTH = 300;
export const PORTRAIT_SUB_BET_BUTTON_HEIGHT = 180;
export const PORTRAIT_SUB_BET_BUTTON_CONTENT_X = 60;
export const PORTRAIT_SUB_BET_BUTTON_CONTENT_Y = 1;
export const PORTRAIT_SUB_BET_BUTTON_CONTENT_WIDTH = 110;
export const PORTRAIT_SUB_BET_BUTTON_CONTENT_HEIGHT = 75;
export const PORTRAIT_SPIN_CONTAINER_X = 300;
export const PORTRAIT_SPIN_CONTAINER_Y = 40;
export const PORTRAIT_SPIN_CONTAINER_WIDTH = 300;
export const PORTRAIT_SPIN_CONTAINER_HEIGHT = 300;
export const PORTRAIT_TURBO_SPIN_BUTTON_X = 450;
export const PORTRAIT_TURBO_SPIN_BUTTON_Y = 90;
export const PORTRAIT_TURBO_SPIN_BUTTON_WIDTH = 160;
export const PORTRAIT_TURBO_SPIN_BUTTON_HEIGHT = 180;
export const PORTRAIT_TURBO_SPIN_BUTTON_CONTENT_X = 0;
export const PORTRAIT_TURBO_SPIN_BUTTON_CONTENT_Y = -60;
export const PORTRAIT_STAKE_BUTTON_X = 450;
export const PORTRAIT_STAKE_BUTTON_Y = 280;
export const PORTRAIT_STAKE_BUTTON_WIDTH = 210;
export const PORTRAIT_STAKE_BUTTON_HEIGHT = 180;
export const PORTRAIT_STAKE_BUTTON_CONTENT_X = 0;
export const PORTRAIT_STAKE_BUTTON_CONTENT_Y = 60;
export const PORTRAIT_SPIN_BUTTON_X = 450;
export const PORTRAIT_SPIN_BUTTON_Y = 190;
export const PORTRAIT_SPIN_BUTTON_WIDTH = 220;
export const PORTRAIT_SPIN_BUTTON_HEIGHT = 220;
export const PORTRAIT_SPIN_BUTTON_CONTENT_X = 0;
export const PORTRAIT_SPIN_BUTTON_CONTENT_Y = -18;
export const PORTRAIT_INFO_BUTTON_X = 650;
export const PORTRAIT_INFO_BUTTON_Y = 350;
export const PORTRAIT_INFO_BUTTON_WIDTH = 100;
export const PORTRAIT_INFO_BUTTON_HEIGHT = 100;
export const PORTRAIT_INFO_BUTTON_CONTENT_X = 0;
export const PORTRAIT_INFO_BUTTON_CONTENT_Y = 0;
export const PORTRAIT_AUTOPLAY_BUTTON_X = 180;
export const PORTRAIT_AUTOPLAY_BUTTON_Y = 320;
export const PORTRAIT_AUTOPLAY_BUTTON_WIDTH = 180;
export const PORTRAIT_AUTOPLAY_BUTTON_HEIGHT = 180;
export const PORTRAIT_AUTOPLAY_BUTTON_CONTENT_X = 0;
export const PORTRAIT_AUTOPLAY_BUTTON_CONTENT_Y = 0;

export const PORTRAIT_SPIN_BUTTON_CONTENT_WIDTH = 240;
export const PORTRAIT_SPIN_BUTTON_CONTENT_HEIGHT = 270;
export const PORTRAIT_TURBO_SPIN_BUTTON_CONTENT_WIDTH = 60;
export const PORTRAIT_TURBO_SPIN_BUTTON_CONTENT_HEIGHT = 60;
export const PORTRAIT_SOUND_BUTTON_X = 540;
export const PORTRAIT_SOUND_BUTTON_Y = 350;
export const PORTRAIT_SETTINGS_BUTTON_X = 430;
export const PORTRAIT_SETTINGS_BUTTON_Y = 350;

export const LANDSCAPE_ADD_BET_BUTTON_X = 135;
export const LANDSCAPE_ADD_BET_BUTTON_Y = 190;
export const LANDSCAPE_ADD_BET_BUTTON_WIDTH = 250;
export const LANDSCAPE_ADD_BET_BUTTON_HEIGHT = 180;
export const LANDSCAPE_ADD_BET_BUTTON_CONTENT_X = -70;
export const LANDSCAPE_ADD_BET_BUTTON_CONTENT_Y = 1;
export const LANDSCAPE_ADD_BET_BUTTON_CONTENT_WIDTH = 110;
export const LANDSCAPE_ADD_BET_BUTTON_CONTENT_HEIGHT = 75;
export const LANDSCAPE_SUB_BET_BUTTON_X = 360;
export const LANDSCAPE_SUB_BET_BUTTON_Y = 190;
export const LANDSCAPE_SUB_BET_BUTTON_WIDTH = 250;
export const LANDSCAPE_SUB_BET_BUTTON_HEIGHT = 180;
export const LANDSCAPE_SUB_BET_BUTTON_CONTENT_X = 74;
export const LANDSCAPE_SUB_BET_BUTTON_CONTENT_Y = 1;
export const LANDSCAPE_SUB_BET_BUTTON_CONTENT_WIDTH = 110;
export const LANDSCAPE_SUB_BET_BUTTON_CONTENT_HEIGHT = 75;
export const LANDSCAPE_SPIN_CONTAINER_X = 100;
export const LANDSCAPE_SPIN_CONTAINER_Y = 40;
export const LANDSCAPE_SPIN_CONTAINER_WIDTH = 300;
export const LANDSCAPE_SPIN_CONTAINER_HEIGHT = 300;
export const LANDSCAPE_TURBO_SPIN_BUTTON_X = 250;
export const LANDSCAPE_TURBO_SPIN_BUTTON_Y = 110;
export const LANDSCAPE_TURBO_SPIN_BUTTON_WIDTH = 160;
export const LANDSCAPE_TURBO_SPIN_BUTTON_HEIGHT = 180;
export const LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_X = 0;
export const LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_Y = -70;
export const LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_WIDTH = 60;
export const LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_HEIGHT = 60;
export const LANDSCAPE_STAKE_BUTTON_X = 250;
export const LANDSCAPE_STAKE_BUTTON_Y = 295;
export const LANDSCAPE_STAKE_BUTTON_SCALE = 1.1;
export const LANDSCAPE_STAKE_BUTTON_WIDTH = 230;
export const LANDSCAPE_STAKE_BUTTON_HEIGHT = 200;
export const LANDSCAPE_STAKE_BUTTON_CONTENT_X = 0;
export const LANDSCAPE_STAKE_BUTTON_CONTENT_Y = 60;
export const LANDSCAPE_SPIN_BUTTON_X = 250;
export const LANDSCAPE_SPIN_BUTTON_Y = 190;
export const LANDSCAPE_SPIN_BUTTON_WIDTH = 200;
export const LANDSCAPE_SPIN_BUTTON_HEIGHT = 200;
export const LANDSCAPE_SPIN_BUTTON_CONTENT_X = 0;
export const LANDSCAPE_SPIN_BUTTON_CONTENT_Y = -18;
export const LANDSCAPE_SPIN_BUTTON_CONTENT_WIDTH = 240;
export const LANDSCAPE_SPIN_BUTTON_CONTENT_HEIGHT = 270;

export const LANDSCAPE_INFO_BUTTON_X = 1275;
export const LANDSCAPE_INFO_BUTTON_Y = 420;
export const LANDSCAPE_INFO_BUTTON_CONTENT_X = 0;
export const LANDSCAPE_INFO_BUTTON_CONTENT_Y = 0;

export const LANDSCAPE_SETTINGS_BUTTON_X = 1035;
export const LANDSCAPE_SETTINGS_BUTTON_Y = 420;

export const LANDSCAPE_SOUND_BUTTON_X = 1155;
export const LANDSCAPE_SOUND_BUTTON_Y = 420;

export const LANDSCAPE_AUTOPLAY_BUTTON_X = 250;
export const LANDSCAPE_AUTOPLAY_BUTTON_Y = 345;
export const LANDSCAPE_AUTOPLAY_BUTTON_WIDTH = 180;
export const LANDSCAPE_AUTOPLAY_BUTTON_HEIGHT = 180;
export const LANDSCAPE_AUTOPLAY_BUTTON_CONTENT_X = 0;
export const LANDSCAPE_AUTOPLAY_BUTTON_CONTENT_Y = 0;

export const SMALL_BUTTON_WIDTH = 154;
export const SMALL_BUTTON_HEIGHT = 154;
