import { Ticker, Text, Graphics } from 'pixi.js';

import { registerLogCategory } from '../../debug/privateLogger';

import {
  REELS_COUNT,
  SYMBOLS_PER_REEL,
  SYMBOL_GAP,
  LONG_DIMENSION,
  SHORT_DIMENSION,
  SYMBOL_WIDTH,
  SYMBOL_HEIGHT,
} from './resources/constants';
import { Game } from '../game';
import { IFreeSpinStatus, Orientation } from '../types';

import { ReelsManager } from './ReelsManager';
import { ServiceManager } from './ServiceManager';
import { IGameOutcome } from './service/types';
import AssetLoader from '../assetLoader';
import { dedeAssets } from './resources/assets';
import { FreeSpinManager } from './FreeSpinManager';
import { BigWinManager } from './BigWinManager';
import { AnteBetManager } from './AnteBetManager';
import { AfterLoadManager } from './AfterLoadManager';
import { BackgroundManager } from './BackgroundManager';
import { InfoManager } from './InfoManager';
import DedeBalanceManager from './managers/balanceManager';
import { MainCharacter } from './models/mainCharacter';
import { IEventDetails } from '../gameEvent';
import GameController from './models/gameController';
import LayoutManager from './managers/layoutManager';
import WinHistoryManager from './managers/winHistoryManager';
import { JackpotManager } from './JackpotManager';
import SoundManager from './managers/soundManager';
import { LogoManager } from './LogoManager';

const log = registerLogCategory('Dede-load');

export class Dede extends Game {
  public soundManager: SoundManager;
  public logoManager: LogoManager;
  public reelsManager: ReelsManager;
  public jackpotManager: JackpotManager;
  private mainCharacter: MainCharacter;
  private afterLoadManager: AfterLoadManager;
  public serviceManager: ServiceManager;
  public assetLoader: AssetLoader;
  public freeSpinManager: FreeSpinManager;
  public anteBetManager: AnteBetManager;
  public bigWinManager: BigWinManager;
  public infoManager: InfoManager;
  public controller: GameController;
  private backgroundManager: BackgroundManager;
  protected _balanceManager: DedeBalanceManager;
  protected _layoutManager: LayoutManager;
  protected _winHistoryManager: WinHistoryManager;
  assetsLoaded = false;

  lazyAssetsLoaded = false;
  outcomes: IGameOutcome[] = [];
  currentOutcome?: IGameOutcome;
  prevOutcome?: IGameOutcome;
  reelsWidth: number = 0;

  private _pixiInfoBarContainer!: Graphics;

  constructor() {
    super();

    this.serviceManager = new ServiceManager(this);
    this.soundManager = new SoundManager();
    this.logoManager = new LogoManager(this);
    this.freeSpinManager = new FreeSpinManager(this);
    this.anteBetManager = new AnteBetManager(this);
    this.bigWinManager = new BigWinManager(this);
    this.afterLoadManager = new AfterLoadManager(this);
    this.assetLoader = new AssetLoader(dedeAssets);
    this._layoutManager = new LayoutManager(this);
    this.backgroundManager = new BackgroundManager(this);
    this.infoManager = new InfoManager(this);
    this.controller = new GameController(this);
    this.mainCharacter = new MainCharacter(this);
    this.reelsManager = new ReelsManager(this, {
      onDestroy: () => {},
      onFallComplete: async (multiplier, outcome) => {
        const tumbleWinAmount = outcome?.tumbleWinAmount || 0;
        const totalWinAmount = outcome?.totalWinAmount || 0;

        this.spinWinAmount = tumbleWinAmount;
        this.reelsManager.printWinAmount(outcome);

        if (outcome?.isLastOutcome) {
          this.isRunning = false;
          if (outcome.isFreeSpin ? outcome.isLastFreeSpinOutcome : true)
            this._balanceManager.addToBalance(this.winAmount);

          if (outcome.isFreeSpin)
            this.freeSpinWinAmount = totalWinAmount;

          this.winAmount = totalWinAmount;
        }
        else {
          if (this.initialSpinDone)
            this.isRunning = true;
          else {
            this.initialSpinDone = true;
            this.isRunning = false;
          }
        }
        const historyItem = {
          outcome,
          tumbleWinAmount,
          totalWinAmount,
          multiplier,
          spinCompleted: !!outcome?.isLastOutcome,
        };
        this.history = [...this.history, historyItem];

        if (outcome?.winnings.length)
          this._winHistoryManager.addToWinHistory(historyItem);

        if (outcome ? outcome.isLastOutcome : true)
          await this.onSpinComplete.triggerEvent({ tumbleWinAmount, totalWinAmount });

        this.onFallComplete.triggerEvent({ winAmount: totalWinAmount, multiplier, outcome });
        if (!this.isRunning && this.autoPlayCount) {
          if (this.paused) {
            const handleGameUnPaused = () => {
              this.onGameUnPaused.removeEventListener(handleGameUnPaused);
              this.runReels();
            };
            this.onGameUnPaused.addEventListener(handleGameUnPaused);
          }
          else {
            this.runReels();
          }
        }
      },
    });
    this.jackpotManager = new JackpotManager(this);

    this._balanceManager = new DedeBalanceManager({
      gameDimensionsInterface: this,
    });
    this._winHistoryManager = new WinHistoryManager(this, this.soundManager);
    this.onSpin.addEventListener(() => {
      this._winHistoryManager.clearWinHistory();
    });

    window.addEventListener('resize', () => this.handleWindowResize(this.getOrientation()));
    this.isRunning = true;

    // this.mainCharacter = new MainCharacter(this);
    window.game = this;
  }

  get reelsContainerWidth() {
    return this.reelsManager.backgroundSprite.getBounds().width;
  }

  renderCoordinates() {
    for (let i = 0; i < this.width + this.xOffset * 2; i += 100) {
      const text = new Text();
      text.text = i;
      text.style = { fill: 0xffffff, fontSize: 30 };
      text.x = i;
      text.y = 0;
      this.app.stage.addChild(text);
    }
    for (let i = 0; i < this.height; i += 100) {
      const text = new Text();
      text.text = i;
      text.style = { fill: 0xffffff, fontSize: 30 };
      text.x = 0;
      text.y = i;
      this.app.stage.addChild(text);
    }
  }

  load = async (onProgress: (progress: number) => void) =>
    this.assetLoader.load(
      (progress: number) => {
        if (progress === 1) {
          if (!this.assetsLoaded) {
            this.onAssetsLoaded.triggerEvent();
            this.assetsLoaded = true;
          }
        }
        onProgress(progress);
      },
      (progress: number) => {
        if (progress === 1) {
          this.lazyAssetsLoaded = true;
          this.soundManager.mountSounds();

          this.onSymbolSelection.addEventListener(() => {
            this.soundManager.symbolHighlight?.play();
          });

          // this.onSpin.addEventListener(() => {
          //   this.soundManager.click?.play();
          // });

          this.onExplode.addEventListener(() => {
            this.soundManager.symbolExplode?.play();
          });

          this.onClick.addEventListener(() => {
            this.soundManager.click?.play();
          });
        }
      },
    );

  async mount() {
    await this.backgroundManager.init();
    await this.serviceManager.initGame();

    const gameContainer = document.getElementById('gameContainer');
    if (!gameContainer)
      return false;
    gameContainer.appendChild(this.app.canvas);
    window.__PIXI_APP__ = this.app;
    this.handleWindowResize(this.getOrientation());

    this.afterLoadManager.mountAfterLoadScreen(async () => {
      this.soundManager.mountSounds();
      await this.mainCharacter.mount();
      this.reelsManager.mount();
      this.logoManager.mount();
      this._layoutManager.setupPixiContainers();
      this._balanceManager.mountPixiBalanceContainer(this.app.stage);
      this._winHistoryManager.mount(
        this._layoutManager.winHistoryContainer,
        this._layoutManager.totalWinContainer,
      );
      this.infoManager.mount(this.app.stage);
      this.jackpotManager.mount(this.stake, this.onStakeChange); // Must be mounted after reelsManager
      this.controller.mount();
      this.app.ticker.add((ticker) => this.update(ticker));

      this.onMountDone();
    });
  }

  update(ticker: Ticker) {
    this.reelsManager.update(ticker);
  }

  async runReels(buyFreeSpins: boolean = false) {
    this.onSpin.triggerEvent();
    this.winAmount = 0;
    this.spinWinAmount = 0;
    this.isRunning = true;
    if (this.autoPlayCount > 0) {
      this.autoPlayCount--;
    }
    this.history = [];
    const spendAmount = buyFreeSpins ? this.stake * this.config.freeSpinBuyMultiplier : this.stake;
    if ((this._balanceManager.balance ?? 0) < spendAmount) {
      if (this.autoPlayCount) {
        this.autoPlayCount = 0;
      }
      return;
    }
    this._balanceManager.subtractFromBalance(spendAmount);
    this.soundManager.startTumble();

    this.reelsManager.runReels(buyFreeSpins);
  }

  async simulate(response: string) {
    this.serviceManager.loadSimulateResponse(response);
    await this.runReels();
  }

  async runFreeSpinReels(outcomes: IGameOutcome[]) {
    this.onSpin.triggerEvent();
    this.isRunning = true;
    this.spinWinAmount = 0;
    this.history = [];
    this.reelsManager.runReelsWithOutcomes(outcomes);

    await new Promise((resolve) => setTimeout(resolve, 0));
    const freeSpinEndListener = (event: IEventDetails, freeSpinStatus: IFreeSpinStatus) => {
      if (!freeSpinStatus.active) {
        this.onFreeSpinChange.removeEventListener(freeSpinEndListener);
      }
    };
    this.onFreeSpinChange.addEventListener(freeSpinEndListener);
  }

  handleWindowResize = (orientation: Orientation) => {
    if (!this.app.renderer)
      return;

    if (orientation === 'landscape') {
      const height = window.innerHeight * (LONG_DIMENSION / window.innerWidth);
      let scale = 1;
      let width = LONG_DIMENSION;
      if (height < SHORT_DIMENSION) {
        scale = height / SHORT_DIMENSION;
      }
      this.resize({
        scale,
        width,
        height,
        xOffset: 0,
        yOffset: 0,
      });
    }
    else {
      let scale = 1;
      let width = window.innerWidth * (LONG_DIMENSION / window.innerHeight);
      let height = LONG_DIMENSION;
      if (width < SHORT_DIMENSION) {
        scale = width / SHORT_DIMENSION;
      }
      this.resize({
        scale,
        width,
        height,
        xOffset: 0,
        yOffset: 0,
      });
    }

    this.app.renderer.resize(this.width, this.height);
    this.app.canvas.style.width = window.innerWidth + 'px';
    this.app.canvas.style.height = window.innerHeight + 'px';

    this.reelsWidth = (SYMBOL_WIDTH + SYMBOL_GAP) * REELS_COUNT;
    // this.renderCoordinates();
  };
}
