import { Dede } from '../..';
import { IGamePosition, Orientation } from '../../../types';
import { SpineAnimator } from '../../../../../components/spineAnimator/SpineAnimator';
import { SYMBOL_GAP, SYMBOL_HEIGHT, SYMBOLS_PER_REEL } from '../../resources/constants';
import { IEventDetails } from '../../../gameEvent';
import {
  PORTRAIT_DEFAULT_CHARACTER_SCALE,
  LANDSCAPE_CHARACTER_OFFSET_Y,
  PORTRAIT_CHARACTER_OFFSET_Y,
  LANDSCAPE_DEFAULT_CHARACTER_SCALE,
} from './constants';
import delay from 'delay';

export class MainCharacter {
  private spineAnimator?: SpineAnimator;
  private runTimeout?: NodeJS.Timeout;
  constructor(private game: Dede) {}

  async mount() {
    this.spineAnimator = new SpineAnimator('characterData', 'characterAtlas', () => {
      // this.spineAnimator!.spine!.state.timeScale = 0.3;
    });
    this.spineAnimator.mount('idle2', 'idle2');

    this.game.onTumble.addEventListener(this.handleTumble);
    // this.spineAnimator.spine!.state.data.defaultMix = 0.2;
    const orientation = this.game.getOrientation();
    this.spineAnimator!.spine!.zIndex = orientation === 'portrait' ? 1 : 3;

    const onOrientationChange = async () => {
      this.unmount();
      this.mount();
      this.game.onOrientationChange.removeEventListener(onOrientationChange);
    };

    this.game.onOrientationChange.addEventListener(onOrientationChange);

    this.spineAnimator.spine!.eventMode = 'static';
    this.game.app.stage.addChild(this.spineAnimator.spine!);
    this.game.onResize.addEventListener(this.handleResize);
    this.game.onTumble.addEventListener(this.handleTumble);
    this.handleResize();
  }

  unmount() {
    this.game.onResize.removeEventListener(this.handleResize);
    this.game.onTumble.removeEventListener(this.handleTumble);
    this.game.onOrientationChange.removeEventListener(this.handleResize);
    this.spineAnimator!.unmount();
  }

  handleResize = () => {
    const orientation = this.game.getOrientation();
    if (!this.spineAnimator!.spine) return;
    if (orientation === 'portrait') {
      this.spineAnimator!.spine!.scale = PORTRAIT_DEFAULT_CHARACTER_SCALE;
      this.spineAnimator!.spine!.x =
        1520 * this.game.reelsManager.scale + this.game.reelsManager.container.x;
      this.spineAnimator!.spine!.y =
        this.spineAnimator!.spine.height / 2 + PORTRAIT_CHARACTER_OFFSET_Y;
    } else {
      let scale = LANDSCAPE_DEFAULT_CHARACTER_SCALE;
      this.spineAnimator!.spine!.scale = scale * this.game.reelsManager.scale;
      this.spineAnimator!.spine!.x =
        1150 * this.game.reelsManager.scale + this.game.reelsManager.container.x;
      if (this.game.height > this.spineAnimator?.spine.height!)
        this.spineAnimator!.spine!.y =
          (this.game.height - this.spineAnimator?.spine.height!) / 2 +
          LANDSCAPE_CHARACTER_OFFSET_Y * this.game.reelsManager.scale;
      else {
        scale *= this.game.height / this.spineAnimator?.spine.height!;
        this.spineAnimator!.spine!.scale = scale;
        this.spineAnimator!.spine!.y =
          LANDSCAPE_CHARACTER_OFFSET_Y * (scale / LANDSCAPE_DEFAULT_CHARACTER_SCALE);
      }
    }
  };

  handleTumble = () => {
    // this.spineAnimator!.spine!.state.timeScale = 1;
    // const animation = this.spineAnimator!.spine!.state.setAnimation(0, 'win');
    // animation.listener = {
    //   complete: () => {
    //     this.spineAnimator!.spine!.state.timeScale = 0.3;
    //     this.spineAnimator!.spine!.state.setAnimation(0, 'idle', true);
    //   },
    // };
  };
}
