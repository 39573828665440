import { GameEvent, IEventDetails } from '../../../games/gameEvent';
import { Sound } from '@pixi/sound';
import { Assets } from 'pixi.js';
import CachedSettingsManager from '../../../cachedSettingsManager';
import { simpleAnimationDuration } from '../animationManager';
import { registerLogCategory } from '../../../debug/privateLogger';

CachedSettingsManager.registerSetting('isSoundEnabled', false);
const log = registerLogCategory('SoundBase');

type TSoundDuckingChangeEvent = {
  isDuckingActive: boolean;
};

type TSoundDuckingChangeListener = (
  event: IEventDetails,
  soundDuckingChangeEvent: TSoundDuckingChangeEvent,
) => void;

class SoundBase {
  private _isDuckable = false;
  private _currentDuckingLevel = 0;
  private _duckingVolumeMultiplier!: number;
  private _duckOutDuration!: number;
  private _currentVolume = 1;
  private _duckOutAnimationRemover!: () => void;
  protected _sound!: Sound;
  protected _soundName!: string;
  private _onCompleteSubscribers = new Set<() => void>();

  constructor(
    soundName: string,
    {
      onDuckingChange,
      duckingVolumeMultiplier = 0.3,
      duckOutDuration = 300,
    }: {
      onDuckingChange?: GameEvent<TSoundDuckingChangeListener>;
      duckingVolumeMultiplier?: number;
      duckOutDuration?: number;
    } = {},
  ) {
    log(1)('constructor', soundName, { duckingVolumeMultiplier, duckOutDuration });

    this._sound = Assets.get(soundName);
    this._soundName = soundName;

    this._isDuckable = !!onDuckingChange;
    this._duckingVolumeMultiplier = duckingVolumeMultiplier;
    this._duckOutDuration = duckOutDuration;

    if (this._isDuckable) {
      onDuckingChange?.addEventListener((
        event: IEventDetails,
        { isDuckingActive }: TSoundDuckingChangeEvent,
      ) => {
        if (isDuckingActive)
          this.startDucking();
        else
          this.stopDucking();
      });
    }
    this.volume = this.volume;

    CachedSettingsManager.addListener('isSoundEnabled', () => {
      log(2)('isSoundEnabled change', this._soundName);

      // will activate getters and setters to update all volume stats to correct state
      this.volume = this.volume;
    });
  }

  onComplete(callback: () => void) {
    this._onCompleteSubscribers.add(callback);
  }

  private _handleComplete() {
    log(2)('_handleComplete', this._soundName);

    this._onCompleteSubscribers.forEach((callback) => callback());
    this._onCompleteSubscribers.clear();
  }

  protected _play() {
    this._sound.play({ complete: () => this._handleComplete() });
  }

  get volume() {
    return this._currentVolume;
  }

  get trueVolume() {
    return this._currentVolume
      * (1 - ((1 - this._duckingVolumeMultiplier) * this._currentDuckingLevel))
      * (CachedSettingsManager.get('isSoundEnabled') as boolean ? 1 : 0);
  }

  set volume(volume: number) {
    this._currentVolume = volume;
    this._sound.volume = this.trueVolume;

    log(3)('set volume', { volume, trueVolume: this._sound.volume });
  }

  startDucking() {
    if (this._isDuckable) {
      log(2)('startDucking', this._soundName);

      this._duckOutAnimationRemover?.();

      this._currentDuckingLevel = 1;
      this._sound.volume = this.trueVolume;
    }
  }

  stopDucking() {
    if (this._isDuckable) {
      log(2)('stopDucking', this._soundName);

      this._duckOutAnimationRemover = simpleAnimationDuration(
        this._duckOutDuration,
        ({ progress }) => {
          this._currentDuckingLevel = 1 - progress;
          this._sound.volume = this.trueVolume;
        },
      ).removeAnimation;
    }
  }
}

export default SoundBase;

export type {
  TSoundDuckingChangeEvent,
  TSoundDuckingChangeListener,
};
