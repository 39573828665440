import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { Dede } from './lib/games/dede';
import GameLoader from './components/gameLoader';
import TestHarness from './components/testHarness';
import { TestHarnessClient } from './components/testHarness/testHarnessClient';
import InfoScreen from './components/infoScreen';
import './lib/debug';
// import LogRocket from 'logrocket';

// LogRocket.init('5kgtbt/mmm');

// LogRocket.identify(new Date().toISOString(), {
//   name: new Date().toISOString(),
//   email: '-',
// });

let dede: Dede;

function App() {
  if (!dede)
    dede = new Dede();
  const [progress, setProgress] = React.useState(0);
  const [gameLoaded, setGameLoaded] = React.useState(false);
  useEffect(() => {
    if (window.location.pathname === '/testHarness')
      return;

    dede.load((progress) => {
      setProgress(progress);
      if (progress === 1) {
        dede.mount();
        setGameLoaded(true);
      }
    });

    new TestHarnessClient(dede);
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/testharness" element={<TestHarness />} />
        {/* Define other routes here */}
        <Route
          path="/*"
          element={(
            <div>
              <div id="gameContainer"></div>
              {!gameLoaded && <GameLoader progress={progress} />}
              {/* {gameLoaded && (
                <div className={"tumble_history_container"}>
                  <TumbleHistory game={dede} />
                </div>
              )} */}
              {/* {gameLoaded && (
                <div className={"tumble_win_container"}>
                  <TumbleWin game={dede} />
                </div>
              )} */}
              <InfoScreen game={dede} />
            </div>
          )}
        />
      </Routes>
    </Router>
  );
}

export default App;
