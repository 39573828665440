import { GameEvent } from '../../../games/gameEvent';
import CachedSettingsManager from '../../../cachedSettingsManager';
import SoundBase, { TSoundDuckingChangeListener } from './soundBase';
import SoundEffect from './soundEffect';
import Music from './music';

class SoundManager {
  private _sounds = new Map<string, SoundEffect>();
  private _music = new Map<string, Music>();
  private _onSoundDuckingChange!: GameEvent<TSoundDuckingChangeListener>;
  protected _isMounted = false;
  private _duckingInstigators = new Set<SoundBase>();
  private _isDucking = false;

  constructor() {
    this._onSoundDuckingChange = new GameEvent<TSoundDuckingChangeListener>();
    CachedSettingsManager.registerSetting('isSoundEnabled', false);
  }

  private _checkDuckingStatus() {
    if (this._duckingInstigators.size > 0 && !this._isDucking) {
      this._isDucking = true;
      this._onSoundDuckingChange.triggerEvent({ isDuckingActive: true });
    }
    else if (this._duckingInstigators.size === 0 && this._isDucking) {
      this._isDucking = false;
      this._onSoundDuckingChange.triggerEvent({ isDuckingActive: false });
    }
  }

  duckForSound(sound: SoundBase) {
    this._duckingInstigators.add(sound);
    this._checkDuckingStatus();

    sound.onComplete(() => {
      this._duckingInstigators.delete(sound);
      this._checkDuckingStatus();
    });
  }

  registerSoundEffect(
    soundName: string,
    {
      debounceTime = 150,
      isDuckable = false,
      duckingVolumeMultiplier,
    }: {
      debounceTime?: number;
      isDuckable?: boolean;
      duckingVolumeMultiplier?: number;
    } = {},
  ) {
    this._sounds.set(
      soundName,
      new SoundEffect(
        soundName,
        debounceTime,
        {
          ...(isDuckable ? {
            onDuckingChange: this._onSoundDuckingChange,
            duckingVolumeMultiplier,
          } : {}),
        },
      ),
    );
  }

  registerMusic(
    musicName: string,
    {
      isDuckable = false,
      duckingVolumeMultiplier,
    }: {
      isDuckable?: boolean;
      duckingVolumeMultiplier?: number;
    } = {},
  ) {
    this._music.set(
      musicName,
      new Music(
        musicName,
        {
          ...(isDuckable ? {
            onDuckingChange: this._onSoundDuckingChange,
            duckingVolumeMultiplier,
          } : {}),
        },
      ),
    );
  }

  getSoundEffect(soundName: string) {
    if (!this._isMounted || !this._sounds.has(soundName))
      throw new Error(`Sound ${soundName} is not mounted`);

    return this._sounds.get(soundName);
  }

  getMusic(musicName: string) {
    if (!this._isMounted || !this._music.has(musicName))
      throw new Error(`Music ${musicName} is not mounted`);

    return this._music.get(musicName);
  }
}

export default SoundManager;

export {
  Music,
};
