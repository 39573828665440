import { Container, Graphics, Point, Text } from 'pixi.js';
import { Dede } from '.';
import { IFreeSpinStatus } from '../types';
import accamaxDebug from '../../debug';
import { Spine } from '@pixi/spine-pixi';
import { simpleAnimatePropertiesTo } from '../../game/managers/animationManager';
import { performCurrencyCountUp } from '../../game/managers/animationManager/animations';
import { ColorGradientFilter, DropShadowFilter, GlowFilter, OutlineFilter } from 'pixi-filters';

const bigWinReelSpineScaleFactor = 0.00016;
const bigWinReelXOffsetRatio = 0.5;
const bigWinReelYOffsetRatio = 0.25;
const bigWinDuration = 5800;
const bigWinFadeDuration = 500;
const bigWinCountUpDuration = 1300;
const bigWinValueMaxWidth = 1000;
const bigWinValueFontSize = 112;
const bigWinValueOffsetY = 328;

const winSizeIndex = {
  big: 0,
  mega: 1,
  olympus: 2,
};

export class BigWinManager {
  freeSpinStatus?: IFreeSpinStatus;
  private _bigWinContainer?: Container;
  private _bigWinValueText!: Text;
  private _game!: Dede;
  private _spine?: Spine;
  private _modalMask!: Graphics;

  constructor(private game: Dede) {
    game.onSpinComplete.addEventListener((event, { totalWinAmount, tumbleWinAmount }) => {
      console.log('onSpinComplete', totalWinAmount, tumbleWinAmount);
      const winType = this._getWinType(tumbleWinAmount);
      if (winType) this._mountPopup(tumbleWinAmount, winType);
    });

    this._game = game;
    this._game.onResize.addEventListener(() => this._handleResize());
    accamaxDebug.debug.triggerBigWin = (amount = 150) => {
      const winType = this._getWinType(amount);
      if (!winType) {
        console.error(`${amount} is not a big win amount`);
        return;
      }
      this._mountPopup(amount, this._getWinType(amount) as 'big' | 'mega' | 'olympus');
    };

    game.onFreeSpinChange.addEventListener((event, status) => {
      this.freeSpinStatus = status;
    });
  }

  private _getWinType(winAmount: number) {
    const winSizes = this._game.config?.limits.winSizes;
    if (!winSizes) return null;

    if (winAmount >= this._game.stake * winSizes[winSizeIndex.olympus]) return 'olympus';
    if (winAmount >= this._game.stake * winSizes[winSizeIndex.mega]) return 'mega';
    if (winAmount >= this._game.stake * winSizes[winSizeIndex.big]) return 'big';

    return null;
  }

  private _getWinThreshold(winType: 'big' | 'mega' | 'olympus') {
    const winSizes = this._game.config?.limits.winSizes;
    return winSizes ? winSizes[winSizeIndex[winType]] * this._game.stake : null;
  }

  private _createMask(container: Container) {}

  _mountPopup(winAmount: number, winType: 'big' | 'mega' | 'olympus') {
    console.log('PAUSE game');
    this.game.paused = true;
    this._bigWinContainer = new Container();
    this._game.app.stage.addChild(this._bigWinContainer);
    this._bigWinContainer.zIndex = 40;
    // this._bigWinContainer.alpha = 0;
    this._spine = Spine.from({ skeleton: `${winType}WinData`, atlas: `${winType}WinAtlas` });
    const animation = this._spine.state.setAnimation(0, 'win_start', false);
    animation.listener = {
      complete: () => {
        const idleAnimation = this._spine!.state.setAnimation(0, 'win_idle', true);
        let animationCount = 0;
        idleAnimation.listener = {
          complete: () => {
            if (++animationCount === 4) {
              const idleAnimation = this._spine!.state.setAnimation(0, 'win_end', false);
              idleAnimation.listener = {
                complete: () => {
                  requestAnimationFrame(() => this._unmountPopup());
                },
              };
            }
          },
        };
      },
    };

    this._bigWinContainer.addChild(this._spine);

    const modalMask = new Graphics();
    this._game.app.stage.addChild(modalMask);
    modalMask.rect(0, 0, this._game.app.screen.width, this._game.app.screen.height);
    modalMask.fill(0x000000);
    modalMask.alpha = 0;
    modalMask.zIndex = 39;
    this._modalMask = modalMask;

    this._bigWinValueText = new Text();
    this._bigWinContainer.addChild(this._bigWinValueText);
    this._bigWinValueText.anchor.set(0.5);
    this._bigWinValueText.y = bigWinValueOffsetY;

    this._bigWinValueText.style = {
      fontSize: bigWinValueFontSize,
      letterSpacing: 7,
      fontFamily: 'kanitRegular',
    };

    const outlineFilter = new OutlineFilter(3, 0xdb7e0b);

    const dropShadowFilter = new DropShadowFilter({
      offset: new Point(0, 3),
      blur: 1,
      alpha: 0.8,
      quality: 5,
    });

    const glowFilter = new GlowFilter({
      distance: 3,
      innerStrength: 1,
      color: 0xffffff,
      alpha: 0.15,
    });

    const colorGradientFilter = new ColorGradientFilter({
      type: 0,
      angle: 180,
      replace: true,
      stops: [
        { offset: 0, color: 0xffffff, alpha: 1 },
        { offset: 0.1, color: 0xffffff, alpha: 1 },
        { offset: 0.3, color: 0xffbe39, alpha: 1 },
        { offset: 0.64, color: 0xd6810f, alpha: 1 },
        { offset: 0.9, color: 0xf4b857, alpha: 1 },
        { offset: 1, color: 0xd6810f, alpha: 1 },
      ].map(({ offset, color, alpha }) => {
        return { offset: (offset - 0.5) * 0.5 + 0.5, color: color, alpha };
      }),
    });

    this._bigWinValueText.filters = [
      colorGradientFilter,
      glowFilter,
      outlineFilter,
      dropShadowFilter,
    ];

    performCurrencyCountUp(
      this._bigWinValueText,
      bigWinCountUpDuration,
      0,
      winAmount,
      bigWinValueMaxWidth
    );

    simpleAnimatePropertiesTo(bigWinFadeDuration * 2, this._modalMask, this._modalMask, {
      alpha: { endValue: 0.75 },
    });

    setTimeout(() => {
      this._performFadeOut();
    }, bigWinDuration - bigWinFadeDuration);

    this._handleResize();
  }

  private _performFadeOut() {
    simpleAnimatePropertiesTo(
      bigWinFadeDuration,
      this._modalMask,
      this._modalMask,
      { alpha: { endValue: 0 } },
      { autoEndOnError: true }
    );
    return simpleAnimatePropertiesTo(
      bigWinFadeDuration,
      this._bigWinValueText!,
      this._bigWinValueText!,
      { alpha: { endValue: 0 } },
      { autoEndOnError: true }
    ).promise;
  }

  private _unmountPopup() {
    console.log('UN pause game');
    this.game.paused = false;
    this._bigWinContainer!.parent.removeChild(this._bigWinContainer!);
    this._bigWinContainer!.destroy({ children: true, texture: true });
    this._bigWinContainer = undefined;
    this._modalMask.parent.removeChild(this._bigWinContainer!);
    this._modalMask.destroy({ children: true, texture: true });
  }

  private _handleResize() {
    if (!this._bigWinContainer) return;

    const globalDimensions = this._game.reelsManager.containerSprite.getBounds();

    this._modalMask!.width = this._game.app.screen.width;
    this._modalMask!.height = this._game.app.screen.height;

    this._bigWinContainer!.x = globalDimensions.x + globalDimensions.width * bigWinReelXOffsetRatio;
    this._bigWinContainer!.y =
      globalDimensions.y + globalDimensions.height * bigWinReelYOffsetRatio;
    this._bigWinContainer!.scale = globalDimensions.width * bigWinReelSpineScaleFactor;
  }
}
