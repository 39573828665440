import { Container, Text } from 'pixi.js';
import { Dede } from '.';
import LayoutManager from './managers/layoutManager';
import { IEventDetails } from '../gameEvent';
import { TBalanceEvent } from '../../game/managers/balanceManager/balanceManager.types';

export class InfoManager {
  container?: Container;
  stakeText?: Text;
  rect!: Container;
  width = 0;
  private _containerIsMounted: boolean = false;
  private _updateValueOfTextControl!: ((value?: number) => void);

  constructor(private game: Dede) {
    this.game = game;
  }

  updateValues() {
    if (!this._containerIsMounted)
      return;

    this._updateValueOfTextControl(this.game.stake);
  }

  updatePosition() {
    this.rect.y = this.game.height - 20;
    this.rect.x = 10 + (
      (this.game.width - this.game.reelsContainerWidth) / 2
    );
  }

  mount(parentContainer: Container) {
    if (this._containerIsMounted)
      return;

    const { updateValue, container } = LayoutManager.createBalanceStyleText(parentContainer, {
      x: 10 + (
        (this.game.width - this.game.reelsContainerWidth) / 2
      ),
      y: this.game.height - 20,
      label: 'BET',
    });
    this._updateValueOfTextControl = updateValue;
    this.rect = container;

    this._containerIsMounted = true;

    this.updateValues();

    this.game.onStakeChange.addEventListener(() => {
      this.updateValues();
    });

    this.updatePosition();

    this.game.onResize.addEventListener(() => {
      this.updatePosition();
    });
  }
}
