import { Sprite, Text, TextStyle, Texture, Ticker } from 'pixi.js';
import {
  DESTROY_Y_LIMIT,
  HIGH_SYMBOL_SCALE,
  LOW_SYMBOL_SCALE,
  STANDARD_FPS,
  SYMBOL_GAP,
} from '../../resources/constants';
import { ISymbolEvents } from './types';
import Reel from '../reel/index';
import { Game } from '../../../game';
import { Spine } from '@pixi/spine-pixi';
import delay from 'delay';

export default class Symbol {
  border?: Sprite;
  falling = true;
  stopAnimationNeeded = true;
  multiplierAnimationStarted = false;
  destroying = false;
  exploded = false;
  fallAnimationTime = 0;
  fallAnimationStarted = false;
  velocityY = 0;
  multiplier = 0;
  constructor(
    private reel: Reel,
    public symbolType: number,
    private spine: Spine,
    public position: { start: { x: number; y: number }; end: { y: number } },
    private events: ISymbolEvents,
    public index: number,
  ) {
    this.spine.x = this.position.start.x;
    this.spine.y = this.position.start.y;
    if (symbolType > 100000) {
      this.multiplier = symbolType - 100000;
    }
    this.fallDown(position.end.y);
    if (this.multiplier > 0) {
      this.showText(`x${this.multiplier}`, '#4E5B7DFF', 400);
    }
  }

  destroy() {
    this.destroying = true;
  }

  showText(text: string, color: string, fontSize: number = 50) {
    if (this.symbolType === 9)
      return;
    let scale =
      this.symbolType > 10000 ? 1 : this.symbolType < 5 ? LOW_SYMBOL_SCALE : HIGH_SYMBOL_SCALE;
    const style = new TextStyle({
      dropShadow: true,
    });
    const gameTextElement = new Text({ style });
    gameTextElement.text = text;
    gameTextElement.style = {
      // fontFamily: 'kanitSemiBold',
      fontSize: fontSize / scale,
      fill: color,
      fontWeight: 'bold',
    };

    const textContainer = new Sprite();
    textContainer.addChild(gameTextElement);
    const spineWidth = this.spine.width;
    const spineHeight = this.spine.height;
    this.spine.addChild(textContainer);
    textContainer.x = -gameTextElement.width / 2;
    textContainer.y = -gameTextElement.height / 2;
  }

  select() {
    let selectSpine = Spine.from({ skeleton: 'paylineData', atlas: 'paylineAtlas' });
    this.reel.container.addChild(selectSpine);
    selectSpine.x = this.spine.x;
    selectSpine.y = this.spine.y;
    selectSpine.width = this.spine.width + SYMBOL_GAP / 1.5;
    selectSpine.height = this.spine.height + SYMBOL_GAP / 1.5;
    selectSpine.state.timeScale = 0.7;

    const winAnimation = selectSpine.state.setAnimation(0, 'win', false);
    this.spine.state.setAnimation(0, 'win', false);
    winAnimation.listener = {
      complete: () => {
        setTimeout(async () => {
          this.reel.container.removeChild(selectSpine);
          await delay(5000);
          selectSpine.destroy({ children: true, texture: true });
        }, 0);
      },
    };
  }

  deselect() {
    if (this.border) {
      this.reel.container.removeChild(this.border);
      this.border.destroy();
      this.border = undefined;
    }
  }

  shake = (magnitude: number, duration: number) => {
    const originalX = this.spine.x;
    const originalY = this.spine.y;
    let elapsed = 0;
    const shakeUpdate = () => {
      const elapsedFraction = elapsed / duration;
      const damping = 1 - elapsedFraction;

      const offsetX = Math.random() * magnitude * 2 - magnitude;
      const offsetY = Math.random() * magnitude * 2 - magnitude;

      this.spine.x = originalX + offsetX * damping;
      this.spine.y = originalY + offsetY * damping;

      elapsed += this.reel.game.app.ticker.elapsedMS;

      if (elapsed < duration) {
        requestAnimationFrame(shakeUpdate);
      }
      else {
        this.spine.x = originalX;
        this.spine.y = originalY;
      }
    };
    shakeUpdate();
  };

  // Update Functions
  update(ticker: Ticker) {
    if (this.exploded) {
    }
    else if (this.falling) {
      if (this.spine.y < this.position.end.y) {
        this.updateFalling(ticker);
      }
      if (this.spine.y >= this.position.end.y) {
        this.spine.x = this.position.start.x;
        this.spine.y = this.position.end.y;
        if (!this.fallAnimationStarted) {
          this.fallAnimationStarted = true;
          if (this.stopAnimationNeeded) {
            if (this.symbolType > 100000) {
              if (!this.multiplierAnimationStarted) {
                this.multiplierAnimationStarted = true;
                const winAnimation = this.spine.state.setAnimation(0, 'win', false);
                this.spine.state.timeScale = 3;
                winAnimation.listener = {
                  complete: () => {
                    this.spine.state.timeScale = 1;
                    this.spine.state.setAnimation(0, 'win_idle', true);
                  },
                };
              }
            }
            else
              this.spine.state.setAnimation(0, 'stop', false);

            this.stopAnimationNeeded = false;
          }
          if (this.index === 4)
            this.reel.game.soundManager.symbolLand?.play();
        }
      }
    }
    else if (this.destroying) {
      if (this.spine.y < DESTROY_Y_LIMIT) {
        this.updateFalling(ticker);
      }
    }

    this.checkDestroyingFinished();
    this.checkFallingFinished();
  }

  // updateShaking(ticker: Ticker) {
  //   const fpsRatio = ticker.FPS / STANDARD_FPS;

  //   if (this.fallAnimationTime < this.fallAnimationDuration) {
  //     if (this.fallAnimationTime < this.fallAnimationDuration / 2) {
  //       const diff = this.fallAnimationDuration / 2 - this.fallAnimationTime;
  //       this.spine.y += ((diff / this.fallAnimationDuration) * this.fallAnimationAmount) / fpsRatio;
  //     } else {
  //       const diff = -1 * (this.fallAnimationDuration / 2 - this.fallAnimationTime);
  //       this.spine.y -= ((diff / this.fallAnimationDuration) * this.fallAnimationAmount) / fpsRatio;
  //     }
  //     this.fallAnimationTime += ticker.deltaMS;
  //   } else {
  //     this.fallAnimationDone = true;
  //     this.spine.x = this.position.start.x;
  //     this.spine.y = this.position.end.y;
  //   }
  // }

  updateFalling(ticker: Ticker) {
    const fpsRatio = ticker.FPS / STANDARD_FPS;

    const gravity = this.reel.game.turboSpinActive
      ? Game.settings.animation.symbolAnimation.turboSpinGravity
      : Game.settings.animation.symbolAnimation.gravity;

    this.velocityY += gravity / fpsRatio;
    this.spine.y += this.velocityY;
    this.spine.x += Game.settings.animation.symbolAnimation.wind / fpsRatio;
  }

  // Check Events
  checkDestroyingFinished() {
    if (this.destroying && this.spine.y > DESTROY_Y_LIMIT) {
      this.destroying = false;
      this.events.onDestroy();
    }
  }

  checkFallingFinished() {
    if (this.falling && (this.spine.destroyed || this.spine?.y >= this.position.end.y)) {
      this.falling = false;
      this.velocityY = 0;
      this.events.onFallComplete();
    }
  }

  fallDown(newY: number, clearVelocity = true) {
    if (newY !== this.position.end.y)
      this.stopAnimationNeeded = true;
    this.position.end.y = newY;
    this.falling = true;

    this.fallAnimationStarted = false;
    if (clearVelocity) {
      this.velocityY = 0;
    }
  }

  explode() {
    let spine = Spine.from({ skeleton: 'blastData', atlas: 'blastAtlas' });
    spine.x = this.spine.x;
    spine.y = this.spine.y;
    spine.scale.set(0.1);
    spine.state.timeScale = 1;
    const animation = spine.state.setAnimation(0, 'explotion', false);
    animation.listener = {
      complete: () => {
        setTimeout(() => {
          this.reel.container.removeChild(spine);
          spine.destroy({ children: true, texture: true });
          this.events.onExplodeComplete();
        }, 0);
      },
    };

    this.exploded = true;
    this.reel.container.addChild(spine);
    this.reel.container.removeChild(this.spine);
  }
}
