import { Assets } from 'pixi.js';
import { sound } from '@pixi/sound';

import { registerLogCategory } from '../../debug/privateLogger';

const log = registerLogCategory('AssetLoader');

type TAssetItem = {
  alias: string;
  src: string;
  loadLazy?: boolean;
};

// Asset loader loads a list of assets that it is constructed with (once load is actually triggered).
class Index {
  initialItemsLoaded = false;
  lazyItemsLoaded = false;

  constructor(public items: TAssetItem[]) {
    log(1)('Constructor', { items });

    if (!Assets.loader.parsers?.find(({ name }) => name === 'mp3Parser')) {
      log(3)('mp3Parser not found, adding it');

      if (!Assets.loader.parsers?.find(({ name }) => name === 'soundParser')) {
        log(3)('soundParser not found, adding it');
        Assets.loader.parsers.push({
          name: 'soundParser',
          test: (url) => !!url.match(/^.*\.(mp3|m4a|ogg)$/),
          parse: ({ name, src }) => new Promise((resolve) => {
            const soundInstance = sound.add(name, src);
            resolve(soundInstance);
          }),
        });
      }
    }
  }

  // Returns a promise that will resolve when non-lazy assets are loaded and READY for use.
  // You can give it a progress callback for tracking progress.
  private loadInitialAssets = (onProgress?: (progress: number) => void) => {
    // Prep a list of non-lazy assets from the constructer's assets list and inform PIXI about them
    const initialItems = this.items.filter(({ loadLazy }) => !loadLazy);
    initialItems.forEach(({ alias, src }) => {
      Assets.add({ alias, src });
    });

    log(3)('Start load of initial assets', { initialItems });
    // Tell pixi to start loading the assigned assets.  Return this as the initial loading awaitable promise
    return Assets.load(
      initialItems.map(({ alias }) => alias),
      (progress) => {
        // This is a hack since, just because progress is 1 it doesn't mean the assets are actually ready for use yet,
        // just that they are downloaded.
        const _progress = Math.min(0.999999, progress);
        log(4)('Initial assets load in progress: ', { _progress });
        onProgress?.(_progress);
      },
      // resolution of the load promise means that the items are actually ready for use
    ).then(() => {
      log(2)('Initial assets loaded');
      this.initialItemsLoaded = true;
      onProgress?.(1);
    });
  };

  private loadLazyAssets = (onProgress?: (progress: number) => void) => {
    // Prep a list of lazy assets from the constructer's assets list and inform PIXI about them
    const lazyItems = this.items.filter(({ loadLazy }) => loadLazy);
    lazyItems.forEach(({ alias, src }) => {
      Assets.add({ alias, src });
    });

    log(3)('Start load of lazy assets', { lazyItems });
    // Tell pixi to start loading the assigned assets.  Return this as the lazy loading awaitable promise
    return Assets.load(
      lazyItems.map(({ alias }) => alias),
      (progress) => {
        // This is a hack since, just because progress is 1 it doesn't mean the assets are actually ready for use yet,
        // just that they are downloaded.
        const _progress = Math.min(0.999999, progress);
        log(4)('Lazy assets load in progress: ', _progress);
        onProgress?.(_progress);
      },
      // resolution of the load promise means that the items are actually ready for use
    ).then(() => {
      log(2)('Lazy assets loaded');
      this.lazyItemsLoaded = true;
      onProgress?.(1);
    });
  };

  // Will start loading all assets and return a promise that resolves upon completion of all assets
  load = async (onProgress: (progress: number) => void, onProgressLazy: (progress: number) => void) => {
    log(2)('Initiating asset load', { onProgress, onProgressLazy });

    await this.loadInitialAssets(onProgress);
    return this.loadLazyAssets(onProgressLazy);
  };
}

export default Index;

export {
  type TAssetItem,
};
