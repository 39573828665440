import { Container } from 'pixi.js';
import { registerLogCategory } from '../../../debug/privateLogger';
import { GameEvent, IEventDetails } from '../../../games/gameEvent';
import {
  TBalanceEvent,
  TBalanceManagerProps,
  TGameDimensionsInterface,
  TBalanceChangeListener,
} from './balanceManager.types';

const log = registerLogCategory('BalanceManager');

class BalanceManager {
  // We track a balance locally as this can update before we are ready to show it and then when we do 'mount', we want
  // to immediately show the most recent value.
  // Keep in mind that this may be undefined initially depending on the game.
  protected _balance?: number;
  // Intended for use by the _handleBalanceChanged implementation as a base container to add visual elements to
  protected _pixiContainer!: Container;
  // Expose methods to get the width and height of the game
  protected _gameDimensionsInterface?: TGameDimensionsInterface;

  private _onBalanceChange = new GameEvent<TBalanceChangeListener>();

  constructor({ balance, gameDimensionsInterface }: TBalanceManagerProps = {}) {
    log(1)('constructor', { balance, gameDimensionsInterface });

    // An event listener used to trigger the implementation of _handleBalanceChanged.  This will not do anything to the
    // tracked balance, it is only an effect.
    this._onBalanceChange.addEventListener((event: IEventDetails, balanceEvent: TBalanceEvent) => {
      log(3)('onBalanceChange->triggered', { event, balanceEvent });
      this._handleBalanceChanged(event, balanceEvent);
    });

    this._gameDimensionsInterface = gameDimensionsInterface;

    // Set the balance if it was provided to the constructor.  Otherwise it will need to be set later.
    if (typeof balance !== 'undefined')
      this.setBalance(balance as number);
  }

  isBalanceSet() {
    return typeof this._balance !== 'undefined';
  }

  // Making the game event object reference readOnly
  get onBalanceChange() {
    return this._onBalanceChange;
  }

  // Should only be used when back-end explicitly tells us to set a specific value (like a pulse loop)
  setBalance(balance: number) {
    log(2)('setBalance', { balance, oldBalance: this._balance });
    this._balance = balance;
    this._onBalanceChange.triggerEvent({ newBalance: balance, balanceEventType: 'set' });
  }

  addToBalance(amount: number) {
    log(2)('addToBalance', { amount, isBalanceSet: this.isBalanceSet, oldBalance: this._balance });

    if (!this.isBalanceSet())
      throw new Error('Cannot add to balance, without balance being set');

    const oldBalance = this._balance;
    (this._balance as number) += amount;
    this._onBalanceChange.triggerEvent({ newBalance: amount, oldBalance, balanceEventType: 'add' });
  }

  subtractFromBalance(amount: number) {
    log(2)('subtractFromBalance', { amount, isBalanceSet: this.isBalanceSet, oldBalance: this._balance });

    if (!this.isBalanceSet())
      throw new Error('Cannot subtract from balance, without balance being set');

    const oldBalance = this._balance;
    (this._balance as number) -= amount;
    this._onBalanceChange.triggerEvent({ newBalance: amount, oldBalance, balanceEventType: 'subtract' });
  }

  get balance() {
    return this._balance;
  }

  // This should be overrided by an inheriting class to handle the UI side of balance display
  protected _handleBalanceChanged(event: IEventDetails, balanceEvent: TBalanceEvent) {
    throw new Error('BalanceManager->handleBalanceChanged is an abstract method and should be implemented');
  }
}

export default BalanceManager;
