import { GameEvent, IEventDetails } from '../../games/gameEvent';
import { registerLogCategory } from '../../debug/privateLogger';

type TEmissionEvent<emissionValueT> = {
  value: emissionValueT | undefined;
} | undefined;

type TEmitListener<emissionValueT = unknown> = (
  event: IEventDetails,
  emissionEvent: TEmissionEvent<emissionValueT>,
) => void;

const log = registerLogCategory('Debouncer');

class Debouncer<emissionValueT = unknown> {
  private _nextToEmit!: emissionValueT | undefined;
  private _hasNextEmission!: boolean;
  private _maxEmissionTimer: ReturnType<typeof setTimeout> | undefined;
  private _currentEmissionTimer: ReturnType<typeof setTimeout> | undefined;
  private _maxEmitDelay: number | undefined;
  private _emitIfNotBusy!: boolean;
  private _debounceTime!: number;
  private _onEmit!: GameEvent<TEmitListener<emissionValueT>>;

  constructor(
    debounceTime: number,
    {
      emitIfNotBusy = true,
      maxEmitDelay,
    }: {
      emitIfNotBusy?: boolean;
      maxEmitDelay?: number;
    } = {},
  ) {
    log(1)('constructor', { debounceTime, emitIfNotBusy, maxEmitDelay });
    this._debounceTime = debounceTime;
    this._emitIfNotBusy = emitIfNotBusy;
    this._maxEmitDelay = maxEmitDelay;
    this._hasNextEmission = false;
    this._onEmit = new GameEvent<TEmitListener>();
  }

  get onEmit() {
    return this._onEmit;
  }

  private _processCurrentEmissionTimer() {
    log(2)(
      '_processCurrentEmissionTimer',
      { hasEmission: this._hasNextEmission, maxEmissionTimer: this._maxEmissionTimer },
    );
    this._currentEmissionTimer = undefined;
    if (this._maxEmissionTimer) {
      clearTimeout(this._maxEmissionTimer);
      this._maxEmissionTimer = undefined;
    }

    if (this._hasNextEmission) {
      this._startTimers();
      this._emit();
    }
  }

  private _processMaxEmissionTimer() {
    log(3)('_processMaxEmissionTimer', { value: this._nextToEmit, currentEmissionTimer: this._currentEmissionTimer });
    this._maxEmissionTimer = undefined;
    if (this._currentEmissionTimer) {
      clearTimeout(this._currentEmissionTimer);
      this._currentEmissionTimer = undefined;
    }

    if (this._hasNextEmission) {
      this._startTimers();
      this._emit();
    }
  }

  private _emit() {
    log(2)('_emit', { value: this._nextToEmit, hasEmission: this._hasNextEmission });
    this._hasNextEmission = false;
    this._onEmit.triggerEvent({ value: this._nextToEmit });
  }

  add(value?: emissionValueT) {
    log(2)('add', {
      value,
      hasEmission: this._hasNextEmission,
      currentEmissionTimer: this._currentEmissionTimer,
      maxEmissionTimer: this._maxEmissionTimer,
      nextToEmit: this._nextToEmit,
    });

    this._nextToEmit = value;
    this._hasNextEmission = true;

    if (this._currentEmissionTimer)
      clearTimeout(this._currentEmissionTimer);
    else if (this._emitIfNotBusy)
      this._emit();

    this._startTimers();
  }

  private _startTimers() {
    if (!this._currentEmissionTimer) {
      this._currentEmissionTimer = setTimeout(() => {
        this._processCurrentEmissionTimer();
      }, this._debounceTime);
    }

    if (!this._maxEmissionTimer && this._maxEmitDelay) {
      this._maxEmissionTimer = setTimeout(() => {
        this._processMaxEmissionTimer();
      }, this._maxEmitDelay);
    }
  }
}

export default Debouncer;

export type {
  TEmitListener,
  TEmissionEvent,
};
