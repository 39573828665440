import { Container } from 'pixi.js';
import { registerLogCategory } from '../../../debug/privateLogger';
import { GameEvent, IEventDetails } from '../../../games/gameEvent';
import { TWinHistoryChangeListener, TWinHistoryEvent, TWinHistoryItem } from './winHistory.types';

const log = registerLogCategory('WinHistoryManager');

class WinHistoryManager {
  protected _winHistory: TWinHistoryItem[] = [];
  protected _pixiContainer!: Container;

  private _onWinHistoryChange = new GameEvent<TWinHistoryChangeListener>();

  constructor() {
    log(1)('constructor');

    // An event listener used to trigger the implementation of _handleBalanceChanged.  This will not do anything to the
    // stored history, it is only an effect.
    this._onWinHistoryChange.addEventListener((event: IEventDetails, winHistoryEvent: TWinHistoryEvent) => {
      log(3)('onWinHistoryChange->triggered', { event, winHistoryEvent });
      this._handleWinHistoryChanged(event, winHistoryEvent);
    });

    this.clearWinHistory();
  }

  // Making the game event object reference readOnly
  get onWinHistoryChange() {
    return this._onWinHistoryChange;
  }

  get winHistory() {
    return [...this._winHistory];
  }

  get totalWin() {
    return this._winHistory.reduce((acc, { totalWinAmount }) => acc + totalWinAmount, 0);
  }

  clearWinHistory() {
    const prevWinHistory = [...this._winHistory];
    log(2)('clear', { prevWinHistory });

    this._winHistory = [];
    this._onWinHistoryChange.triggerEvent({ prevWinHistory, winHistoryEventType: 'clear' });
  }

  addToWinHistory(winHistoryItem: TWinHistoryItem) {
    const prevWinHistory = [...this._winHistory];
    log(2)('add', { prevWinHistory, winHistoryItem });

    this._winHistory.push(winHistoryItem);
    this._onWinHistoryChange.triggerEvent({ prevWinHistory, winHistoryItem, winHistoryEventType: 'add' });
  }

  // This should be overrided by an inheriting class to handle the UI side of winHistory display
  protected _handleWinHistoryChanged(event: IEventDetails, winHistoryEvent: TWinHistoryEvent) {
    throw new Error('WinHistoryManager->handleWinHistoryChanged is an abstract method and should be implemented');
  }
}

export default WinHistoryManager;
