import { Application } from 'pixi.js';
import { IGameOutcome } from './dede/service/types';
import {
  AnteBetChangeListener,
  AssetsLoadedListener,
  AutoPlayChangeListener,
  ClickListener,
  OrientationChangeListener,
  ExplodeListener,
  FallCompleteListener,
  FreeSpinChangeListener,
  GameConfigChangeListener,
  GameLoadedListener,
  GameStateChangeListener,
  HistoryChangeListener,
  IFreeSpinStatus,
  IGameConfig,
  ISpinHistoryElement,
  Orientation,
  SymbolSelectionListener,
  SoundChangeListener,
  SpinCompleteListener,
  SpinListener,
  SpinWinAmountChangeListener,
  StakeChangeListener,
  TumbleListener,
  WinAmountChangeListener,
  IGamePosition,
  ResizeListener,
  InfoButtonClickListener,
  BasicListener,
} from './types';
import { GameEvent } from './gameEvent';
import FpsCounter from '../debug/fpsCounter';
import CoreBalanceManager from '../game/managers/balanceManager';
import { ReelsManager } from './dede/ReelsManager';
import WinHistoryManager from '../game/managers/winHistoryManager';
import { SettingsManager } from '../game/managers/settingsManager';

export class Game {
  id: string = '';
  app: Application;
  onSpin = new GameEvent<SpinListener>();
  onSymbolSelection = new GameEvent<SymbolSelectionListener>();
  onExplode = new GameEvent<ExplodeListener>();
  onClick = new GameEvent<ClickListener>();
  onInfoButtonClick = new GameEvent<InfoButtonClickListener>();
  onSoundChange = new GameEvent<SoundChangeListener>();
  onPendingSpinResponseChange = new GameEvent<BasicListener>();
  onWinAmountChange = new GameEvent<WinAmountChangeListener>();
  onSpinWinAmountChange = new GameEvent<SpinWinAmountChangeListener>();
  onStakeChange = new GameEvent<StakeChangeListener>();
  onOrientationChange = new GameEvent<OrientationChangeListener>();
  onLoaded = new GameEvent<GameLoadedListener>();
  onGameStateChange = new GameEvent<GameStateChangeListener>();
  onAnteBetChange = new GameEvent<AnteBetChangeListener>();
  onTumble = new GameEvent<TumbleListener>();
  onAssetsLoaded = new GameEvent<AssetsLoadedListener>();
  onFallComplete = new GameEvent<FallCompleteListener>();
  onSpinComplete = new GameEvent<SpinCompleteListener>();
  onHistoryChange = new GameEvent<HistoryChangeListener>();
  onAutoplayChange = new GameEvent<AutoPlayChangeListener>();
  onGameConfigChange = new GameEvent<GameConfigChangeListener>();
  onResize = new GameEvent<ResizeListener>();
  onFreeSpinChange = new GameEvent<FreeSpinChangeListener>();
  onNewSymbolsComing = new GameEvent<BasicListener>();
  onOldSymbolsDropping = new GameEvent<BasicListener>();
  onSpacePressed = new GameEvent<BasicListener>();
  onGamePaused = new GameEvent<BasicListener>();
  onGameUnPaused = new GameEvent<BasicListener>();
  static settings = new SettingsManager();

  private _pendingSpinResponse = false;
  private _freeSpinWinAmount = 0;
  private _freeSpinTotalMultiplier = 0;
  private _freeSpinActive = false;
  private _winAmount: number = 0;
  private _spinWinAmount: number = 0;
  private _stake: number = 0;
  private _orientation: Orientation = 'portrait';
  private _loaded = false;
  private _isRunning: boolean = false;
  private _anteBetActive: boolean = false;
  private _history: ISpinHistoryElement[] = [];
  private _autoPlayCount: number = 0;
  private _gamePosition: IGamePosition = { width: 0, height: 0, xOffset: 0, yOffset: 0, scale: 1 };
  private _paused = false;
  private _turboSpinActive = false;

  protected _balanceManager!: CoreBalanceManager;
  protected _winHistoryManager!: WinHistoryManager;

  private _config: IGameConfig = {
    payTable: {
      symbolPayouts: [],
    },
    limits: {
      defaultBet: 0,
      legalBets: [],
      autoBets: [],
      winSizes: [],
    },
    freeSpinBuyMultiplier: 0,
    scatterSymbol: 0,
  };

  index = 0;
  initialSpinDone = false;

  constructor() {
    if (this.constructor === Game) {
      throw new Error('Cannot instantiate an abstract class.');
    }

    this.app = new Application();

    // Will register the fps counter system but won't run it unless told to do so from settings (see md file)
    new FpsCounter(this.app);

    window.addEventListener('resize', () => {
      const newOrientation = this.getOrientation();
      if (newOrientation !== this._orientation) {
        this._orientation = newOrientation;
        this.onOrientationChange.triggerEvent(newOrientation);
      }
    });
    setTimeout(() => {
      this.onOrientationChange.triggerEvent(this.getOrientation());
    }, 500);
    this.id = Math.random().toString(36).substring(2, 9);
    this.onAutoplayChange.addEventListener((event, { newAutoPlayCount, prev }) => {
      if (prev === 0 && newAutoPlayCount) {
        this.runReels();
      }
    });

    // get spacebar key press
    window.addEventListener('keydown', (e) => {
      if (e.code === 'Space') {
        if (this.isRunning)
          return;
        this.runReels();
        this.onSpacePressed.triggerEvent();
      }
    });
  }

  // Abstract method
  runReels(buyFreeSpins: boolean = false): void {
    throw new Error('Abstract method \'runReels\' must be implemented.');
  }

  simulate(response: string): void {
    throw new Error('Abstract method \'simulate\' must be implemented.');
  }

  onGoldenBetClick(): void {
    throw new Error('Abstract method \'onGoldenBetClick\' must be implemented.');
  }

  public get turboSpinActive(): boolean {
    return this._turboSpinActive;
  }

  public set turboSpinActive(value: boolean) {
    this._turboSpinActive = value;
  }

  public get paused(): boolean {
    return this._paused;
  }

  public set paused(value: boolean) {
    this._paused = value;
    if (value) {
      this.onGamePaused.triggerEvent();
    }
    else {
      this.onGameUnPaused.triggerEvent();
    }
  }

  public get pendingSpinResponse(): boolean {
    return this._pendingSpinResponse;
  }

  public set pendingSpinResponse(value: boolean) {
    this._pendingSpinResponse = value;
    this.onPendingSpinResponseChange.triggerEvent();
  }

  public get width(): number {
    return this._gamePosition.width;
  }

  public get height(): number {
    return this._gamePosition.height;
  }

  public get xOffset(): number {
    return this._gamePosition.xOffset;
  }

  public get yOffset(): number {
    return this._gamePosition.yOffset;
  }

  public get scale(): number {
    return this._gamePosition.scale;
  }

  public get history(): ISpinHistoryElement[] {
    return this._history;
  }

  public set history(value: ISpinHistoryElement[]) {
    this._history = value;
    this.onHistoryChange.triggerEvent(value);
  }

  get balanceManager() {
    return this._balanceManager;
  }

  public get loaded(): boolean {
    return this._loaded;
  }

  public set loaded(value: boolean) {
    if (value) {
      this._loaded = value;
      this.onLoaded.triggerEvent();
    }
  }

  public get autoPlayCount(): number {
    return this._autoPlayCount;
  }

  public set autoPlayCount(value: number) {
    const prev = this._autoPlayCount;
    this._autoPlayCount = value;
    //    console.log('autoPlayCount', value, prev);
    if (prev !== value)
      this.onAutoplayChange.triggerEvent({ newAutoPlayCount: value, prev });
  }

  public get winAmount(): number {
    return this._winAmount;
  }

  public set winAmount(value: number) {
    // console.log('winAmount', value);
    const prev = this._winAmount;
    this._winAmount = value;
    this.onWinAmountChange.triggerEvent({ newWinAmount: value, diff: Math.min(value - prev, 0) });
  }

  public get spinWinAmount(): number {
    return this._spinWinAmount;
  }

  public set spinWinAmount(value: number) {
    this._spinWinAmount = value;
    this.onSpinWinAmountChange.triggerEvent(value);
  }

  public get freeSpinWinAmount(): number {
    return this._freeSpinWinAmount;
  }

  public set freeSpinWinAmount(value: number) {
    this._freeSpinWinAmount = value;
    this.onFreeSpinChange.triggerEvent({
      active: this._freeSpinActive,
      totalWinning: this._freeSpinWinAmount,
      totalMultiplier: this._freeSpinTotalMultiplier,
    });
  }

  public get freeSpinTotalMultiplier(): number {
    return this._freeSpinTotalMultiplier;
  }

  public set freeSpinTotalMultiplier(value: number) {
    const prev = this._freeSpinTotalMultiplier;
    this._freeSpinTotalMultiplier = value;
    if (prev !== value)
      this.onFreeSpinChange.triggerEvent({
        active: this._freeSpinActive,
        totalWinning: this._freeSpinWinAmount,
        totalMultiplier: this._freeSpinTotalMultiplier,
      });
  }

  public get freeSpinActive(): boolean {
    return this._freeSpinActive;
  }

  public set freeSpinActive(value: boolean) {
    this._freeSpinActive = value;
    this.onFreeSpinChange.triggerEvent({
      active: this._freeSpinActive,
      totalWinning: this._freeSpinWinAmount,
      totalMultiplier: this._freeSpinTotalMultiplier,
    });
  }

  public get stake(): number {
    return this._stake;
  }

  public set stake(value: number) {
    this._stake = value;
    this.onStakeChange.triggerEvent(value);
  }

  public get isRunning(): boolean {
    return this._isRunning;
  }

  protected set isRunning(value: boolean) {
    this._isRunning = value;
    this.onGameStateChange.triggerEvent(value);
  }

  public get anteBetActive(): boolean {
    return this._anteBetActive;
  }

  public set anteBetActive(value: boolean) {
    const prev = this._anteBetActive;
    this._anteBetActive = value;
    if (prev !== value) {
      this.onAnteBetChange.triggerEvent(value);
    }
  }

  public get config(): IGameConfig {
    return this._config;
  }

  public set config(value: IGameConfig) {
    if (JSON.stringify(this._config) === JSON.stringify(value))
      return;
    this._config = value;
    this.onGameConfigChange.triggerEvent(value);
  }

  // methods

  tumble(outcome: IGameOutcome) {
    this.onTumble.triggerEvent(outcome);
  }

  onMountDone() {
    this.loaded = true;
    this.onOrientationChange.triggerEvent(this.getOrientation());
  }

  getOrientation(): Orientation {
    return window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
  }

  resize = (newPosition: IGamePosition) => {
    this._gamePosition = newPosition;
    this.onResize.triggerEvent(newPosition);
  };
}
