import WinHistoryManagerCore from '../../../../game/managers/winHistoryManager';
import { Container, Sprite, Text, Texture } from 'pixi.js';
import { GameEvent, IEventDetails } from '../../../gameEvent';
import { TWinHistoryEvent, TWinHistoryItem } from '../../../../game/managers/winHistoryManager/winHistory.types';
import { formatAsCurrency } from '../../../../game/managers/currencyManager';
import accamaxDebug, { getDebugName } from '../../../../debug';
import { simpleAnimatePropertiesTo } from '../../../../game/managers/animationManager';
import { TGameOutcome } from '../../../../game/game.baseTypes';
import { GlowFilter } from 'pixi-filters';
import { FreeSpinChangeListener, IFreeSpinStatus } from '../../../types';
import {
  performFadeInAnimation,
  performFadeOutAnimation,
  performPulseAnimation,
} from '../../../../game/managers/animationManager/animations';
import { registerLogCategory } from '../../../../debug/privateLogger';
import { changeComponentVisibility } from '../../../../layoutTools/pixiComponentManagement';
import ScrollableContainerInterface, { TSnapScrollEvent } from '../../../../layoutTools/scrollableContainerInterface';
import SoundManager from '../soundManager';

const log = registerLogCategory('WinHistoryManager');

type TGameStatsInterface = {
  freeSpinActive: boolean;
  onFreeSpinChange: GameEvent<FreeSpinChangeListener>;
};

const verticalElementsAllowed = 2;
const horizontalElementsAllowed = 2;
const winHistoryWidthLimit = 430;
const winningsBarHeight = 74;

const winHistoryFontSize = 28;
const textOffset = -2.5;
const textGlowDistance = 10;
const textGlowStrength = 4;
const symbolGlowDistance = 4;
const symbolGlowStrength = 3;
const fsTextGlowDistance = 4;
const fsTextGlowStrength = 3;
const fsSymbolGlowDistance = 3;
const fsSymbolGlowStrength = 2;

const dividerWidth = 3;
const dividerHeight = 60;
const symbolCountPadLeft = 15;
const symbolCountTextWidth = 25;
const symbolSpaceLeft = 0;
const symbolWidth = 30;
const symbolHeight = 30;
const winAmountPadRight = 18;

const totalWinHeadingFontSize = 32;
const totalWinValueYOffset = 30;
const totalWinValueFontSize = 30;
const totalWinXOffset = 25;
const totalWinYOffset = -2;

const totalWinFadeInDuration = 300;
const totalWinFadeOutDuration = 300;
const winHistoryElementFadeOutDuration = 300;
const winHistoryElementFadeInDuration = 300;

const winTotalUpdateAnimation = {
  maxBrightness: 2,
  maxGlowStrength: 8,
  maxGlowDistance: 12,
  maxGrowScale: 1.1,
  durationGrow: 150,
  durationShrink: 150,
};

class WinHistoryManager extends WinHistoryManagerCore {
  private _historyCells: Container[];
  private _historyValueElements: Text[];
  private _historySymbolElements: Sprite[];
  private _historyDividers: Sprite[];
  private _historySymbolCountElements: Text[];
  private _symbolTextures!: Texture[];
  private _gameStatsInterface: TGameStatsInterface;
  private _soundManager!: SoundManager;
  private _glowFilterText!: GlowFilter;
  private _glowFilterSymbol!: GlowFilter;
  private _fsGlowFilterText!: GlowFilter;
  private _fsGlowFilterSymbol!: GlowFilter;
  private _totalWinIsMounted = false;
  private _totalWinCurrentValue = 0;
  private _totalWinContainer!: Container;
  private _totalWinValueText!: Text;
  private _appWinHistoryContainer!: Container;
  private _appTotalWinContainer!: Container;
  private _winHistoryScrollingInterface!: ScrollableContainerInterface;

  constructor(gameStatsInterface: TGameStatsInterface, soundManager: SoundManager) {
    log(1)('constructor', { gameStatsInterface });
    super();

    this._historyCells = [];
    this._historyValueElements = [];
    this._historySymbolElements = [];
    this._historySymbolCountElements = [];
    this._historyDividers = [];
    this._gameStatsInterface = gameStatsInterface;
    this._soundManager = soundManager;

    accamaxDebug.debug.addFakeWinHistoryItem = () => {
      this._addWinHistoryItem(
        Math.floor(Math.random() * 10),
        Math.pow((Math.random() * 2.5) + 0.8, 5),
        Math.floor(Math.pow(Math.random() * 2.5, 2)) + 8,
      );
    };
  }

  mount(winHistoryContainer: Container, totalWinContainer: Container) {
    log(1)('mount', { winHistoryContainer, totalWinContainer, gameStatsInterface: this._gameStatsInterface });

    this._appWinHistoryContainer = winHistoryContainer;
    this._appTotalWinContainer = totalWinContainer;
    this._historyCells = [];
    this._historyDividers = [];
    this._historyValueElements = [];
    this._historySymbolElements = [];
    this._historySymbolCountElements = [];

    const cellWidth = winHistoryWidthLimit / horizontalElementsAllowed;

    for (let i = 1; i < horizontalElementsAllowed; i++) {
      const dividerSprite = new Sprite(Texture.from('winHistoryDivider'));
      this._appWinHistoryContainer.addChild(dividerSprite);
      this._historyDividers.push(dividerSprite);
      dividerSprite.anchor.set(0.5);
      dividerSprite.x = i * cellWidth;
      dividerSprite.y = winningsBarHeight / 2;
      dividerSprite.height = dividerHeight;
      dividerSprite.width = dividerWidth;
      dividerSprite.alpha = 0;
    }

    this._symbolTextures = [
      Texture.from('s1'),
      Texture.from('s2'),
      Texture.from('s3'),
      Texture.from('s4'),
      Texture.from('s5'),
      Texture.from('s6'),
      Texture.from('s7'),
      Texture.from('s8'),
      Texture.from('s9'),
      Texture.from('s10'),
    ];

    this._glowFilterText = new GlowFilter({
      distance: textGlowDistance,
      outerStrength: textGlowStrength,
      innerStrength: 0,
      color: 0xffffff,
      quality: 0.5,
    });

    this._glowFilterSymbol = new GlowFilter({
      distance: symbolGlowDistance,
      outerStrength: symbolGlowStrength,
      innerStrength: 0,
      color: 0xffffff,
      quality: 0.5,
    });

    this._fsGlowFilterText = new GlowFilter({
      distance: fsTextGlowDistance,
      outerStrength: fsTextGlowStrength,
      innerStrength: 0,
      color: 0xffffff,
      quality: 0.5,
    });

    this._fsGlowFilterSymbol = new GlowFilter({
      distance: fsSymbolGlowDistance,
      outerStrength: fsSymbolGlowStrength,
      innerStrength: 0,
      color: 0xffffff,
      quality: 0.5,
    });

    this._winHistoryScrollingInterface = new ScrollableContainerInterface(this._appWinHistoryContainer, {
      activeRegion: {
        x: 0,
        y: 0,
        width: winHistoryWidthLimit,
        height: winningsBarHeight,
      },
    });

    this._winHistoryScrollingInterface.onSnapScroll.addEventListener((event, { newIndexX }: TSnapScrollEvent) => {
      log(2)('onSnapScroll', { newIndexX });
      this._soundManager.click?.play();
      this._updateWinHistoryCellPositions(newIndexX);
    });

    this._winHistoryScrollingInterface.enableSnapScrolling({
      dragXSnapScrollThreshold: winHistoryWidthLimit / horizontalElementsAllowed / 1.5,
      snapScrollXRange: 1,
      snapScrollYRange: 1,
      wheelChangesWhichAxis: 'x',
    });

    this._deleteAllHistoryCells();

    this._gameStatsInterface.onFreeSpinChange.addEventListener((event, freeSpinEvent) => {
      this._handleFreeSpinModeChanged(event, freeSpinEvent);
    });
  }

  private async _deleteAllHistoryCells() {
    log(2)('_deleteAllHistoryCells', { historyCells: this._historyCells });
    if (!this._historyCells)
      return;

    await performFadeOutAnimation(this._appWinHistoryContainer, winHistoryElementFadeOutDuration);

    this._historyCells.forEach((container) => {
      if (container.parent)
        container.parent.removeChild(container);

      container.destroy({
        children: true,
        texture: true,
      });
    });

    this._historyDividers.forEach((historyDivider) => {
      historyDivider.alpha = 0;
    });

    this._historyCells = [];
    this._historyValueElements = [];
    this._historySymbolElements = [];
    this._historySymbolCountElements = [];

    this._winHistoryScrollingInterface.updateSnapScrollRange({
      snapScrollXRange: 1,
      snapScrollYRange: 1,
    });
  }

  private async _addWinHistoryItem(symbolIndex: number, winValue: number, symbolCount: number) {
    await performFadeOutAnimation(this._appWinHistoryContainer, winHistoryElementFadeOutDuration);

    this._historyCells.push(this._createCell(symbolIndex, winValue, symbolCount));

    this._updateWinHistoryCellPositions();

    this._winHistoryScrollingInterface.updateSnapScrollRange({
      snapScrollXRange: Math.max(
        1,
        Math.ceil(this._historyCells.length / verticalElementsAllowed) - horizontalElementsAllowed + 1,
      ),
      snapScrollYRange: 1,
    });

    return performFadeInAnimation(this._appWinHistoryContainer, winHistoryElementFadeInDuration);
  }

  private _updateWinHistoryCellPositions(hOffset = 0) {
    log(3)('_updateWinHistoryCellPositions', {
      hOffset,
      historyCells: this._historyCells,
      historyDividers: this._historyDividers,
      winHistoryWidthLimit,
      horizontalElementsAllowed,
      verticalElementsAllowed,
    });

    const cellWidth = winHistoryWidthLimit / horizontalElementsAllowed;
    const cellHeight = winningsBarHeight / verticalElementsAllowed;
    const visibleCellsCount = horizontalElementsAllowed * verticalElementsAllowed;
    const visibleStart = hOffset * 2;

    this._historyCells.forEach((historyCell, ind) => {
      const visibleIndex = this._historyCells.length - ind - 1 - visibleStart;
      const hPosition = Math.floor(visibleIndex / verticalElementsAllowed);
      const vPosition = visibleIndex % verticalElementsAllowed;
      log(4)('updateWinHistoryItemPosition', {
        ind,
        visibleIndex,
        hPosition,
        vPosition,
        visibleCellsCount,
        historyCell,
        cellWidth,
        cellHeight,
      });

      if (hPosition > 0 && !vPosition && hPosition < horizontalElementsAllowed)
        this._historyDividers[hPosition - 1].alpha = 1;

      historyCell.x = cellWidth * hPosition;
      historyCell.y = cellHeight * (vPosition + 0.5);
      changeComponentVisibility(
        this._appWinHistoryContainer,
        historyCell,
        visibleIndex < visibleCellsCount && visibleIndex >= 0,
      );
    });
  }

  private _createCell(
    symbolIndex: number,
    winValue: number,
    symbolCount: number,
  ) {
    log(3)('_createCell', {
      symbolIndex,
      winValue,
      symbolCount,
      historyCells: this._historyCells,
      historySymbolCountElements: this._historySymbolCountElements,
      gameStatsInterface: this._gameStatsInterface,
      symbolTextures: this._symbolTextures,
      historySymbolElements: this._historySymbolElements,
      historyValueElements: this._historyValueElements,
      historyDividers: this._historyDividers,
    });

    const container = new Container();
    this._appWinHistoryContainer.addChild(container);

    const symbolCountText = new Text();
    container.addChild(symbolCountText);
    this._historySymbolCountElements.push(symbolCountText);
    symbolCountText.anchor.set(0, 0.5);
    symbolCountText.x = symbolCountPadLeft;
    symbolCountText.y = textOffset;
    symbolCountText.text = symbolCount;
    symbolCountText.style = {
      fill: 0x000000,
      fontSize: winHistoryFontSize,
      fontFamily: 'brlnsr',
    };
    symbolCountText.filters = [this._gameStatsInterface.freeSpinActive ? this._fsGlowFilterText : this._glowFilterText];

    const symbolSprite = new Sprite(this._symbolTextures[symbolIndex]);
    container.addChild(symbolSprite);
    this._historySymbolElements.push(symbolSprite);
    symbolSprite.anchor.set(0, 0.5);
    symbolSprite.x = symbolCountPadLeft + symbolCountTextWidth + symbolSpaceLeft;
    symbolSprite.y = 0;
    symbolSprite.width = symbolWidth;
    symbolSprite.height = symbolHeight;
    symbolSprite.filters = [
      this._gameStatsInterface.freeSpinActive ? this._fsGlowFilterSymbol : this._glowFilterSymbol,
    ];

    const winAmountText = new Text();
    container.addChild(winAmountText);
    this._historyValueElements.push(winAmountText);
    winAmountText.anchor.set(1, 0.5);
    winAmountText.text = formatAsCurrency(winValue);
    winAmountText.style = {
      fill: 0x000000,
      fontSize: winHistoryFontSize,
      fontFamily: 'brlnsr',
    };
    winAmountText.x = (winHistoryWidthLimit / horizontalElementsAllowed) - winAmountPadRight;
    winAmountText.y = textOffset;
    winAmountText.filters = [this._gameStatsInterface.freeSpinActive ? this._fsGlowFilterText : this._glowFilterText];

    return container;
  }

  _handleFreeSpinModeChanged(event: IEventDetails, { active }: IFreeSpinStatus) {
    log(2)('_handleFreeSpinModeChanged', { active });

    this._historySymbolCountElements.forEach((element) => {
      element.filters = [active ? this._fsGlowFilterText : this._glowFilterText];
    });

    this._historySymbolElements.forEach((element) => {
      element.filters = [active ? this._fsGlowFilterSymbol : this._glowFilterSymbol];
    });

    this._historyValueElements.forEach((element) => {
      element.filters = [active ? this._fsGlowFilterText : this._glowFilterText];
    });

    this._totalWinContainer.filters = [
      this._gameStatsInterface.freeSpinActive ? this._fsGlowFilterText : this._glowFilterText,
    ];
  }

  private _createTotalWinContainer() {
    log(1)('_createTotalWinContainer');

    this._totalWinContainer = new Container();
    this._appTotalWinContainer.addChild(this._totalWinContainer);
    this._totalWinContainer.alpha = 0;
    this._totalWinIsMounted = true;
    this._totalWinContainer.x = totalWinXOffset;
    this._totalWinContainer.filters = [
      this._gameStatsInterface.freeSpinActive ? this._fsGlowFilterText : this._glowFilterText,
    ];

    const totalWinHeadingText = new Text();
    this._totalWinContainer.addChild(totalWinHeadingText);
    totalWinHeadingText.text = 'Total WIn';
    totalWinHeadingText.style = {
      fill: 0x000000,
      fontSize: totalWinHeadingFontSize,
      fontFamily: 'caesarDressingRegular',
    };

    const totalWinValueText = new Text();
    this._totalWinContainer.addChild(totalWinValueText);
    this._totalWinValueText = totalWinValueText;
    totalWinValueText.text = '';
    totalWinValueText.style = {
      fill: 0x000000,
      fontSize: totalWinValueFontSize,
      fontFamily: 'brlnsr',
    };

    totalWinValueText.y = totalWinValueYOffset;

    this._totalWinContainer.y = (winningsBarHeight - this._totalWinContainer.height) / 2 + totalWinYOffset;
  }

  private async _updateTotalWin(winAmount: number, debugName: string) {
    log(3)('_updateTotalWin', {
      winAmount,
      debugName,
      _totalWinIsMounted: this._totalWinIsMounted,
      _totalWinCurrentValue: this._totalWinCurrentValue,
      _totalWinValueText: this._totalWinValueText,
      _totalWinContainer: this._totalWinContainer,
    });

    if (!this._appTotalWinContainer)
      return;

    if (!this._totalWinIsMounted) {
      this._createTotalWinContainer();
      this._totalWinIsMounted = true;
    }

    // Full fade out
    if (this._totalWinCurrentValue && !winAmount) {
      log(4)('perform master fade out');
      await simpleAnimatePropertiesTo(
        totalWinFadeOutDuration,
        this._totalWinContainer,
        this._totalWinContainer,
        { alpha: { endValue: 0 } },
        { debugName: getDebugName('winHistoryManager-totalWinFadeOut') },
      ).promise;
      log(4)('master fade out complete');

      this._totalWinCurrentValue = winAmount;
      return;
    }

    let inPromise: Promise<void> | undefined = undefined;
    // Full fade in
    if (winAmount && !this._totalWinCurrentValue) {
      log(4)('perform master fade in');
      this._totalWinValueText.text = formatAsCurrency(winAmount);

      inPromise = simpleAnimatePropertiesTo(
        totalWinFadeInDuration,
        this._totalWinContainer,
        this._totalWinContainer,
        { alpha: { endValue: 1 } },
        { debugName: getDebugName('winHistoryManager-totalWinFadeIn') },
      ).promise;
    }

    // Transition value (existing or full fade in)
    if (winAmount) {
      log(4)('perform value transition animation');
      inPromise = performPulseAnimation(
        this._totalWinValueText,
        {
          midChangeCallback: () => {
            this._totalWinValueText.text = formatAsCurrency(winAmount);
          },
          ...winTotalUpdateAnimation,
        },
      );
    }

    if (inPromise) {
      await inPromise;
      log(4)('fadeIn / valueTransition animation(s) complete');

      this._totalWinCurrentValue = winAmount;
    }
  }

  protected _handleWinHistoryChanged(event: IEventDetails, { winHistoryItem, winHistoryEventType }: TWinHistoryEvent) {
    log(2)('_handleWinHistoryChanged', { winHistoryItem, winHistoryEventType, historyCells: this._historyCells });

    switch (winHistoryEventType) {
      case 'clear':
        this._deleteAllHistoryCells();
        this._updateTotalWin(0, getDebugName());
        break;

      case 'add':
        const _outcome = (winHistoryItem as TWinHistoryItem).outcome as TGameOutcome;
        _outcome.winnings.forEach(({ winAmount, winningSymbol, symbols }) => {
          this._addWinHistoryItem(
            winningSymbol,
            winAmount,
            symbols.length,
          );

          this._updateTotalWin(_outcome.runningTotal, getDebugName());
        });
        break;

      default:
        throw new Error('Unsupported event type for winHistoryEventType.');
    }
  }
};

export default WinHistoryManager;
